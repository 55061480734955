<template>
  <h4 class="center-header">
    <span class="center-theme">我要建议</span>
  </h4>
  <el-form
    class="complain-form"
    ref="joinFormRef"
    label-width="120px"
    label-suffix=":"
    :model="joinForm"
  >
    <el-form-item label="建议标题" prop="name" required>
      <el-input v-model="joinForm.name" autocomplete="off"></el-input>
      <div class="form-item-error">标题不能为空</div>
    </el-form-item>
    <el-form-item label="建议内容" prop="content" required>
      <el-input v-model="joinForm.content" autocomplete="off" type="textarea"></el-input>
    </el-form-item>
    <el-form-item class="form-item-button">
      <el-button type="primary" @click="submitForm">提交建议</el-button>
    </el-form-item>
  </el-form>

  <div class="marginTb"></div>
  <h4 class="center-header">
    <span class="center-theme">建议列表</span>
  </h4>
  <el-table :data="dataSource" style="width: 100%" empty-text="暂无数据">
    <el-table-column label="建议主题" prop="drugNm">
      <template #default="scope">
        {{ scope.row.drugNm }}
      </template>
    </el-table-column>
    <el-table-column label="添加时间" prop="createTime">
      <template #default="scope">
        {{ scope.row.createTime }}
      </template>
    </el-table-column>
    <el-table-column label="状态" prop="content">
      <template #default="scope">
        {{ scope.row.content }}
      </template>
    </el-table-column>
    <el-table-column label="查看" prop="reply">
      <template #default="scope">
        {{ scope.row.reply }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-13 16:11:07
 *@version: V1.0.0
 *@description: 站点建议
 *************************************************************************/
export default {
  name: "RmkComplain",
  components: {},
  props: {
    info: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      dataSource: this.info,
      joinForm: {
        name: "",
        contetn: "",
      },
    };
  },
  computed: {},
  watch: {
    info: {
      deep: true,
      handler(nval) {
        this.$nextTick(() => {
          this.dataSource = [...nval];
        });
      },
    },
  },
  methods: {
    submitForm() {
      this.$refs.joinFormRef
        .validate()
        .then((r) => {})
        .catch((err) => {
          return err;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.marginTb {
  margin-top: 15px;
}
:deep(.el-form-item__error) {
  display: none;
}
:deep(.el-form-item__content) {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  .el-input {
    width: 400px;
  }
}
.complain-form {
  padding: 20px 0;
  :deep(.el-textarea__inner) {
    height: 80px;
    width: 460px;
  }
}
.form-item-error {
  padding-left: 12px;
  font-size: 12px;
}
</style>
