<template>
  <div class="container">
    <h3 class="floor-title"><span>精选商业</span></h3>
    <el-row :gutter="15">
      <el-col
        v-for="(info, i) in storeOpt"
        :key="i"
        :xs="6"
        :sm="6"
        :md="5"
        :lg="5"
        :xl="5"
      >
        <div class="store-content" @click="jumpToPage(info.id)">
          <img :src="info.img" :alt="info.name" />
          <p>{{ info.name }}</p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-12 15:56:20
 *@version: V1.0.0
 *@description: 入驻商家list
 *************************************************************************/
export default {
  name: "FloorStore",
  components: {},
  props: {},
  data() {
    return {
      storeOpt: [
        {
          link: "",
          id: 12,
          img: require("../../../assets/grvi0o06dybylgc321gx.jpg"),
          name: "九州通生活旗舰店",
        },
        {
          link: "",
          id: 21,
          img: require("../../../assets/zhfteivlzjbkdng2i1pl.png"),
          name: "恩济日用旗舰店",
        },
        {
          link: "",
          id: 52,
          img: require("../../../assets/kc4cy6qge4f4r82nyg3h.png"),
          name: "明朗生活旗舰店",
        },
        {
          link: "",
          id: 33,
          img: require("../../../assets/0g9dag60igf5an93zkjh.jpg"),
          name: "佳能达母婴旗舰店",
        },
      ],
    };
  },

  computed: {},
  watch: {},

  methods: {
    jumpToPage($id) {
      this.$router.push({
        path: "/businessInfo",
        query: {
          id: $id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.floor-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 50px;
  padding-left: 30px;
  margin-top: 15px;
  position: relative;
  text-align: left;
  &::before {
    content: "";
    width: 5px;
    height: 18px;
    background-color: #2c3e50;
    border-radius: 3px;
    position: absolute;
    left: 10px;
    top: 16px;
  }
}
.store-content {
  padding: 10px;
  background-color: #fff;
  border-radius: 16px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  img {
    width: 150px;
    height: 150px;
  }
  &:hover {
    box-shadow: 0 3px 15px rgba(0, 0, 0, 30%);
    P {
      color: #022a75;
    }
  }
}
</style>
