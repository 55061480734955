<template>
  <div>
    <el-dialog
      v-model="visible"
      title="添加收货地址"
      width="30%"
      center
      :close-on-click-modal="false"
      @close="modalClose"
    >
      <el-form
        :model="addressForm"
        :rules="addressRules"
        ref="addressFormRefs"
        label-width="96px"
        label-suffix=":"
      >
        <el-form-item label="姓名" prop="userNm">
          <el-input v-model="addressForm.userNm" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address" class="item-adress-select">
          <el-select
            v-model="addressForm.province"
            @change="queryCurCityOpts"
            :append-to-body="false"
            placeholder="请选择"
          >
            <el-option
              v-for="item in provinceOpts"
              :key="item.province"
              :value="item.province"
              :label="item.name"
            >
              {{ item.name }}
            </el-option>
          </el-select>
          <el-select v-model="addressForm.city" @change="queryCurAreaOpts">
            <el-option
              v-for="item in cityOpts"
              :key="item.code"
              :value="item.city"
              :label="item.name"
              >{{ item.name }}</el-option
            >
          </el-select>
          <el-select v-model="addressForm.area">
            <el-option
              v-for="item in areaOpts"
              :key="item.code"
              :value="item.area"
              :label="item.name"
              >{{ item.name }}</el-option
            >
          </el-select>
        </el-form-item>
        <el-form-item label="详细地址" prop="addressDetail">
          <el-input v-model="addressForm.addressDetail" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="telephone">
          <el-input v-model="addressForm.telephone" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="固话" prop="phone">
          <el-input v-model="addressForm.phone" maxlength="12"></el-input>
        </el-form-item>
        <el-form-item label="邮编" prop="postcode">
          <el-input v-model="addressForm.postcode" maxlength="6"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="submitAddressForm">添加</el-button>
          <el-button @click="modalClose">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-14 13:01:08
 *@version: V1.0.0
 *@description: 地址对话框modal
 *************************************************************************/
const { province, city, area } = require("province-city-china/data");
import { reactive } from "vue";
import { mapGetters, mapActions } from "vuex";
export function validMobile(mobile) {
  const reg = /^1(3|4|5|6|7|8|9)[0-9]{9}$/;
  return reg.test(mobile);
}
export default {
  name: "AddressModal",
  components: {},
  props: {
    isShowDialog: {
      type: Boolean,
      default: false,
    },
    addressInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    const validateMobilePhone = async (rule, value) => {
      if (value !== "") {
        if (value.length < 11) {
          return Promise.reject("手机号码需不少于11位");
        } else {
          if (!validMobile(value)) {
            return Promise.reject("请输入正确手机号码");
          } else {
            return Promise.resolve();
          }
        }
      } else {
        return Promise.reject("请输入手机号");
      }
    };
    const validatePostCode = async (rule, value) => {
      const regExp = /^\d{6}$/;
      if (value) {
        if (regExp.test(value)) {
          return Promise.resolve();
        } else {
          return Promise.reject("邮编格式不正确，请重新录入");
        }
      } else {
        return Promise.reject("请填写邮编");
      }
    };
    return {
      visible: this.isShowDialog,
      addressForm: reactive({
        userNm: "",
        address: "",
        addressDetail: "",
        telephone: "",
        phone: "",
        postcode: "",
        province: "",
        city: "",
        area: "",
      }),
      addressRules: {
        userNm: [
          {
            required: true,
            whitespace: true,
            trigger: "blur",
            blur: "change",
            message: "请填写收货人姓名",
          },
        ],
        address: [
          {
            required: true,
            whitespace: true,
            trigger: "blur",
            blur: "change",
            message: "请选择收货地址",
          },
        ],
        addressDetail: [
          {
            required: true,
            whitespace: true,
            trigger: "blur",
            blur: "change",
            message: "请填写收货人详细地址",
          },
        ],
        telephone: [
          {
            required: true,
            whitespace: true,
            trigger: "blur",
            blur: "change",
            validator: validateMobilePhone,
          },
        ],
        postcode: [
          {
            required: true,
            whitespace: true,
            trigger: "blur",
            blur: "change",
            validator: validatePostCode,
          },
        ],
      },
      confirmLoad: false,
      provinceOpts: province,
      city,
      area,
      cityOpts: [],
      areaOpts: [],
    };
  },
  computed: {
    ...mapGetters(["userAddress"]),
  },
  watch: {
    isShowDialog(val) {
      this.visible = val; // 对话框--true-显示；false-隐藏
      if (val) {
        this.modalShow(); // 打开对话框
      }
    },
    addressForm: {
      deep: true,
      handler(nval) {
        const { province, city, area } = nval;
        if (province && city && area) {
          nval.address = `${province}-${city}-${area}`;
        } else {
          nval.address = "";
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      setUserAddress: "user/setUserAddress",
      setEditUserAddress: "user/setEditUserAddress",
    }),
    modalShow() {
      this.provinceOpts = [...province];
      console.log("===this.addressInfo==", this.addressInfo);
      if (this.addressInfo) {
        this.addressForm = reactive(this.addressInfo);
        this.initQueryCityOpts();
        this.initQueryAreaOPts();
      }
    },
    modalClose() {
      this.$emit("update:isShowDialog", false); // 直接修改父组件的属性
      this.resetForm();
    },
    resetForm() {
      this.$refs.addressFormRefs.resetFields();
      this.addressForm = reactive({
        userNm: "",
        address: "",
        addressDetail: "",
        telephone: "",
        phone: "",
        postcode: "",
        province: "",
        city: "",
        area: "",
      });
      this.confirmLoad = false;
    },
    initQueryCityOpts() {
      const { province } = this.addressForm;
      for (let i = 0; i < this.city.length; i++) {
        const item = this.city[i];
        if (province == item.province) {
          this.cityOpts.push(item);
        }
      }
      if (province && this.cityOpts.length == 0) {
        this.cityOpts[0] = {
          code: `${province}0100`,
          name: "市辖区",
          province: province,
          city: "01",
        };
      }
    },
    // change查询市选项
    queryCurCityOpts() {
      this.cityOpts = [];
      this.areaOpts = [];
      this.addressForm.city = "";
      this.addressForm.area = "";
      this.initQueryCityOpts();
    },
    initQueryAreaOPts() {
      const { province, city } = this.addressForm;
      for (let i = 0; i < this.area.length; i++) {
        const item = this.area[i];
        if (province == item.province && city == item.city) {
          this.areaOpts.push(item);
        }
      }
    },
    // change查询区选项
    queryCurAreaOpts() {
      this.areaOpts = [];
      this.addressForm.area = "";
      this.initQueryAreaOPts();
    },
    handlerAddressProvice() {
      const { province, city, area } = this.addressForm;
      for (let i = 0; i < this.provinceOpts.length; i++) {
        const item = this.provinceOpts[i];
        if (province == item.province) {
          this.addressForm.provinceNm = item.name;
        }
      }
      for (let j = 0; j < this.cityOpts.length; j++) {
        const item = this.cityOpts[j];
        if (city == item.city) {
          this.addressForm.cityNm = item.name;
        }
      }
      for (let j = 0; j < this.areaOpts.length; j++) {
        const item = this.areaOpts[j];
        if (area == item.area) {
          this.addressForm.areaNm = item.name;
        }
      }
    },
    submitAddressForm() {
      const len = this.userAddress.length;
      this.$refs.addressFormRefs
        .validate()
        .then(() => {
          this.handlerAddressProvice();
          if (this.addressInfo) {
            this.setEditUserAddress(this.addressForm);
          } else {
            this.addressForm.id = len + 1;
            this.setUserAddress(this.addressForm);
          }
          this.modalClose();
        })
        .catch((err) => {
          return err;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.item-adress-select {
  :deep(.el-form-item__content) {
    flex-flow: row nowrap;
    justify-content: space-between;
    .el-select {
      width: 140px;
    }
  }
}
:deep(.el-overlay) {
  z-index: 1000 !important;
}
</style>
