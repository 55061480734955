<template>
  <nav class="nav-bar">
    <h5>{{ nav.title }}</h5>
    <ul>
      <li v-for="(item, i) in nav.option" :key="i">
        <a :href="`/ucenter?id=${item.key}`" :class="{isCurrent: item.key==showSect}">{{ item.menuNm }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-13 14:35:06
 *@version: V1.0.0
 *@description: 功能描述
 *************************************************************************/
export default {
  name: "NavAside",
  components: {},
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      nav: this.info,
      showSect: this.$route.query.id,
    };
  },

  computed: {},
  watch: {
    info: {
      deep: true,
      handler(nval) {
        this.$nextTick(() => {
          this.nav = { ...nval };
        });
      },
    },
  },

  methods: {},
};
</script>
<style lang="less" scoped>
.nav-bar {
  h5 {
    font-size: 14px;
    text-align: center;
    margin: 9px 30px 11px;
    color: #888;
    font-weight: 400;
    position: relative;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 7px;
      display: block;
      height: 1px;
      width: 45px;
      background: #888;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
  li {
    padding: 10px 0;
    line-height: 20px;
    text-align: left;
    a {
      display: block;
      padding-left: 29px;
      color: #333;
      font-size: 14px;
      &.isCurrent{
        color: #f98800;
      }
    }
  }
}
</style>
