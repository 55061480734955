<template>
  <div class="production-item" @click="jumpToPage(info.id)">
    <div class="production-img">
      <img :src="info.img" :alt="info.cDrugNm" />
    </div>
    <div class="production-slide">
      <p class="production-title">
        <span class="production-name">{{ info.cDrugNm }}</span>
      </p>
      <el-button type="primary">立即购买</el-button>
    </div>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-19 15:41:49
 *@version: V1.0.0
 *@description: 商品item
 *************************************************************************/
export default {
  name: "ProductionItem",
  components: {},
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },

  computed: {},
  watch: {},

  methods: {
    jumpToPage($id) {
      const productPage = this.$router.resolve({
        path: "/product",
        query: {
          id: $id,
        },
      });
      window.open(productPage.href, "_blank");
    },
  },
};
</script>
<style lang="less" scoped>
.production-item {
  position: relative;
  height: 266px;
  transition: 0.5s;
  background-color: #fff;
  cursor: pointer;
  .production-img {
    margin-bottom: 14px;
    overflow: hidden;
    img {
      width: 70%;
      height: auto;
      max-height: 160px;
      transition: all 0.5s ease-in-out;
    }
  }
  &:hover {
    .production-img {
      img {
        transform: scale(1.1);
      }
    }
    top: 2px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 30%);
  }
}

.production-slide {
  width: 100%;
  height: 62px;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}
.production-title {
  margin-bottom: 8px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  color: #000;
  font-size: 14px;
}
</style>
