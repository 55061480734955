<template>
  <!-- 检索框 -->
  <div class="search-flex-box">
    <el-input v-model="searchVal" class="search-input">
      <template #append>
        <el-button @click="jumpToSearchCallback"
          ><el-icon><search /></el-icon
        ></el-button>
      </template>
    </el-input>
    <el-popover
      placement="bottom"
      trigger="hover"
      class="top-shopping-cart"
      width="360px"
    >
      <template #reference>
        <el-badge :value="cartTotalCount" class="item">
          <el-button @click="jumpToPage"
            ><el-icon><shopping-cart /></el-icon
          ></el-button>
        </el-badge>
      </template>
      <p v-if="shopCartList.length == 0">购物车空空如也</p>
      <ul v-else>
        <li v-for="(item, i) in shopCartList" :key="i" class="display-flex">
          <img :src="item.img" :alt="item.cDrugNm" width="60" />
          <div class="cart-desc">
            <h6>{{ item.cDrugNm }}</h6>
            <p>￥{{ item.retailPrice }}*{{ item.count }}</p>
          </div>
          <div class="cart-icon">
            <el-icon @click.prevent="deleteRow(i)"><close /></el-icon>
          </div>
        </li>
      </ul>
    </el-popover>
    <el-button @click="jumpToUcenter"
      ><el-icon><user /></el-icon>{{ userName ? userName : "我的商城" }}</el-button
    >
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-12 08:40:12
 *@version: V1.0.0
 *@description: 顶部检索框组件
 *************************************************************************/
import { Search, ShoppingCart, User, Close } from "@element-plus/icons-vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "HeaderSearch",
  components: {
    Search,
    ShoppingCart,
    User,
    Close,
  },
  props: {},
  data() {
    return {
      searchVal: "",
    };
  },

  computed: {
    ...mapGetters(["shopCartList", "cartTotalCount", "userName"]),
  },
  watch: {},

  methods: {
    ...mapActions({
      calculateCartCount: "carts/calculateCartCount",
    }),
    jumpToPage() {
      this.$router.push({
        path: "/shopCart",
      });
    },
    jumpToUcenter() {
      if (this.userName.length > 0) {
        this.$router.push({
          path: "/ucenter",
          query: {
            id: "index",
          },
        });
      } else {
        this.$router.push({
          path: "/register",
          query: {
            tab: "login",
          },
        });
      }
    },
    jumpToSearchCallback() {
      this.$router.push({
        path: "/callback",
        query: {
          type: "search",
        },
      });
    },
    deleteRow(i) {
      this.shopCartList.splice(i, 1);
      this.calculateCartTotal();
    },
    calculateCartTotal() {
      let totalCount = 0;
      if (this.shopCartList) {
        for (let i = 0; i < this.shopCartList.length; i++) {
          const { count } = this.shopCartList[i];
          totalCount += count;
        }
        this.calculateCartCount(totalCount);
      }
    },
  },
};
</script>
<style lang="less" scoped>
@height: 40px;
.search-flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .search-input {
    width: 468px;
    height: @height;
    :deep(.el-input__inner) {
      height: @height;
      line-height: @height;
    }
  }
}
.display-flex {
  display: flex;
  padding: 10px 15px;
  align-items: center;
  width: 100%;
  position: relative;
  .cart-desc {
    flex: auto;
  }
  h6 {
    white-space: pre-wrap;
    font-size: 14px;
  }
  p {
    text-align: right;
    font-size: 12px;
  }
  .cart-icon {
    position: absolute;
    right: 0;
    top: 10px;
    &:hover {
      :deep(.el-icon) {
        color: red;
        cursor: pointer;
      }
    }
  }
}

:deep(.el-button) {
  height: @height;
}
</style>
