<template>
  <h4 class="center-header">
    <span class="center-theme">我的积分</span>
    <span class="float-right">您当前的积分：<b>0</b> 分</span>
  </h4>
  <el-table :data="dataSource" style="width: 100%" empty-text="暂无数据">
    <el-table-column label="积分日期" prop="createTime">
      <template #default="scope">
        {{ scope.row.createTime }}
      </template>
    </el-table-column>
    <el-table-column label="积分" prop="numId">
      <template #default="scope">
        {{ scope.row.numId }}
      </template>
    </el-table-column>
    <el-table-column label="说明" prop="status" >
      <template #default="scope">
        {{ scope.row.status }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-13 15:29:52
 *@version: V1.0.0
 *@description: 我的积分
 *************************************************************************/
export default {
  name: "RmkIntegral",
  components: {},
  props: {
    info: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      dataSource: this.info,
    };
  },
  computed: {},
  watch: {
    info: {
      deep: true,
      handler(nval) {
        this.$nextTick(() => {
          this.dataSource = [...nval];
        });
      },
    },
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.center-header {
  clear: both;
  overflow: hidden;
  &::after,
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 0;
  }
}
.float-right {
  float: right;
  font-weight: normal;
  font-size: 14px;
  b {
    color: #022a75;
  }
}

</style>
