<template>
  <el-row class="recommond-row">
    <el-col :span="24">
      <div class="container">
        <h3 class="recommond-tab-title">
          <div class="recommond-tab-div">
            <span>明星产品</span>
          </div>
        </h3>
        <ul class="recommond-list">
          <li v-for="(item, i) in recommedOpts" :key="i">
            <div class="recommond-item">
              <span :style="{ backgroundImage: `url(${item.imgSrc})` }"></span>
            </div>
          </li>
        </ul>
      </div>
    </el-col>
  </el-row>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-12 13:35:06
 *@version: V1.0.0
 *@description: 功能描述
 *************************************************************************/
export default {
  name: "RecommendList",
  components: {},
  props: {},
  data() {
    return {
      recommedOpts: [
        {
          imgSrc: require("../assets/images/bh20220421160005.png")
        },
        {
          imgSrc: require("../assets/yo37yxro9kird9emg76s.jpg"),
        },
        {
          // imgSrc: require("../assets/vft6exemr76vtvvgukko.png"),
          imgSrc: "http://www.ofmom.cn/assets/images/banners/s-HARMAS-4.jpg",
        },
      ],
    };
  },

  computed: {},
  watch: {},

  methods: {},
};
</script>
<style lang="less" scoped>
.recommond-row {
  padding: 0 1.5rem;
  margin-bottom: 15px;
}
.recommond-tab-title {
  font-size: 16px;
  line-height: 40px;
  text-align: left;
  .recommond-tab-div {
    border-bottom: 2px solid #022a75;
    span {
      display: inline-block;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-width: 5px;
        border-style: dashed dashed solid dashed;
        border-color: transparent transparent #022a75;
      }
    }
  }
}
.recommond-list {
  display: flex;
  align-items: center;
  margin: 10px auto;
  .recommond-item {
    border-radius: 8px;
    margin-right: 15px;
    overflow: hidden;
    span {
      display: block;
      width: 286px;
      aspect-ratio: auto 222 / 112;
      height: 114px;
      background-size: 100%;
      background: no-repeat center / cover;
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      span {
        transform: scale(1.03) translateY(3px);
        box-shadow: 0 4px 4px -5px rgba(9, 2, 4, 20%);
      }
    }
  }
}
</style>
