/****************
 *@author: niubj
 *@date: 2021-09-15 11:31:50
 *@version: V1.0.0
 *@description: 购物车数据：（新增、移除、清除全部）
 *************************************************************************/
const shopCartList = localStorage.getItem("shopCartList");
const unitGood = sessionStorage.getItem("unitGoodOrder");
const state = {
  shopCartList: shopCartList ? JSON.parse(shopCartList) : [],
  cartTotalCount: localStorage.getItem("cartTotalCount") || 0,
  unitGoods: unitGood ? JSON.parse(unitGood) : {}, //单个产品订单
};
const mutations = {
  /**
   * 添加商品
   * @param {*} state
   * @param {*} data
   */
  addGoodsCart(state, data) {
    let target = state.shopCartList.find((item) => item.id == data.id);
    if (target) {
      if (target.type == "detail") {
        target.count += data.count;
      } else {
        target.count = data.count;
      }
      target.subtotal = (target.count * target.retailPrice).toFixed(2);
    } else {
      data.subtotal = (data.count * data.retailPrice).toFixed(2);
      state.shopCartList.push(Object.assign({}, data));
    }
    localStorage.setItem("shopCartList", JSON.stringify(state.shopCartList));
  },
  /**
   * 删除当前页商品
   * @param {*} state
   * @param {*} data
   */
  closeVisitedRoute(state, data) {
    state.shopCartList.forEach((item, i) => {
      if (item.path === data.path) {
        state.shopCartList.splice(i, 1);
      }
    });
  },
  /**
   * 清空购物车全部商品
   * @param {*} state
   * @param {*} data
   */
  clearGoodsCart(state) {
    state.shopCartList = [];
    localStorage.removeItem("shopCartList");
  },
  /**
   * 删除除了首页以外的全部商品
   * @param {*} state
   * @param {*} data
   */
  closeAllVisitedRoute(state) {
    state.shopCartList = state.shopCartList.filter((item) => {
      return item.meta.affixed;
    });
  },
  /**
   * 计算购物车物品总数量
   */
  calculateCartCount(state, data) {
    state.cartTotalCount = data;
    localStorage.setItem("cartTotalCount", data);
  },
  addUnitGoodOrder(state, data) {
    state.unitGoods = { ...data };
    sessionStorage.setItem("unitGoodOrder", JSON.stringify(data));
  },
};
const actions = {
  /**
   * 新增商品
   * @param {*} param0
   * @param {*} data
   */
  addGoodsCart({ commit }, data) {
    commit("addGoodsCart", data);
  },
  // 关闭当前商品
  closeVisitedRoute({ commit }, data) {
    commit("closeVisitedRoute", data);
  },
  // 清空购物车商品
  clearGoodsCart({ commit }, data) {
    commit("clearGoodsCart", data);
  },
  // 关闭全部商品
  closeAllVisitedRoute({ commit }) {
    commit("closeAllVisitedRoute");
  },
  calculateCartCount({ commit }, data) {
    commit("calculateCartCount", data);
  },
  addUnitGoodOrder({commit}, data) {
    commit("addUnitGoodOrder", data);
  }
};
export default {
  state,
  mutations,
  actions,
};
