<template>
  <div class="login-box">
    <div class="login-content">
      <el-tabs class="page-tabs" v-model="tabsActiveName" @tab-click="tabClickName">
        <el-tab-pane key="1" label="登录" name="login">
          <el-form
            class="login-form"
            ref="loginFormRef"
            layout="vertical"
            label-position="top"
            :model="loginForm"
            :rules="loginRules"
          >
            <el-form-item label="用户名" prop="username">
              <el-input
                v-model="loginForm.username"
                placeholder="请输入用户名或邮箱"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input
                class="login-form-password"
                v-model="loginForm.password"
                placeholder="请输入密码"
                autocomplete="off"
                type="password"
                @keyup.enter="onSubmit"
              ></el-input>
            </el-form-item>
            <el-form-item class="login-form-btn">
              <el-button class="login-btn" @click="onSubmit">登录</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane key="3" label="注册" name="register">
          <el-form
            class="register-form"
            ref="registerFormRef"
            label-width="100px"
            :model="registerForm"
            :rules="registerRules"
          >
            <el-form-item label="用户名" prop="name" required>
              <el-input
                v-model="registerForm.name"
                placeholder="请输入您的用户名"
              ></el-input>
              <div class="form-item-tips">2-20个字符，可以为字母、数字、下划线和中文</div>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input
                class="login-form-password"
                v-model="registerForm.password"
                placeholder="请输入密码"
                autocomplete="off"
                type="password"
              ></el-input>
              <div class="form-item-tips">填写登录密码，6-32个字符</div>
            </el-form-item>
            <el-form-item label="再次输入密码" prop="checkPass">
              <el-input
                class="login-form-password"
                v-model="registerForm.checkPass"
                placeholder="请再次输入密码"
                autocomplete="off"
                type="password"
              ></el-input>
              <div class="form-item-tips">重复上面所填写的密码</div>
            </el-form-item>
            <el-form-item prop="code" class="item-captcha">
              <el-input v-model="registerForm.code"></el-input>
              <vue-captcha
                ref="captcha"
                :style="{ height: '32px', marginLeft: '4px' }"
                v-model:captcha.sync="registerForm.originalCode"
                @on-change="handleChange"
              >
              </vue-captcha>
            </el-form-item>
            <el-form-item prop="agree">
              <el-checkbox v-model="registerForm.agree">
                我已阅读并同意<a href="/agreement?id=2">《服务协议》</a>
              </el-checkbox>
            </el-form-item>
            <el-form-item class="login-form-btn">
              <el-button class="login-btn" @click="onSubmitRegister">注册</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2021-11-24 14:41:28
 *@version: V1.0.0
 *@description: 登录 / 注册
 *************************************************************************/
import VueCaptcha from "vue-captcha-code";
import { mapActions } from "vuex";
import { ElMessage } from "element-plus/lib/components";
export function validEmail(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  reg.lastIndex = 0;
  return reg.test(email);
}

export default {
  name: "Register",
  components: { VueCaptcha },
  props: {},
  data() {
    const validatePassword = async (rule, value) => {
      if (value !== "") {
        if (value.length < 6) {
          return Promise.reject("密码长度不能少于6位数！");
        } else {
          return Promise.resolve();
        }
      } else {
        return Promise.reject("请输入密码");
      }
    };
    const validatePass2 = async (rule, value) => {
      if (value === "") {
        return Promise.reject("请您再次输入密码");
      } else if (value !== this.registerForm.password) {
        return Promise.reject("两次填写的密码不一致");
      } else {
        return Promise.resolve();
      }
    };
    return {
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [
          {
            required: true,
            whitespace: true,
            trigger: "blur",
            blur: "change",
            message: "用户名不可以为空！",
          },
        ],
        password: [
          {
            required: true,
            validator: validatePassword,
            min: 6,
            trigger: "blur",
            blur: "submit",
          },
        ],
      },
      registerForm: {
        name: "",
        password: "",
        checkPass: "",
        agree: true,
        code: "",
        originalCode: "",
      },
      registerRules: {
        code: [
          {
            required: true,
            validator: (rule, value) => {
              if (value == this.registerForm.originalCode) {
                return Promise.resolve();
              } else {
                return Promise.reject("验证码错误！");
              }
            },
            trigger: "blur",
            blur: "submit",
          },
        ],
        password: [
          {
            required: true,
            validator: validatePassword,
            min: 6,
            trigger: "blur",
            blur: "submit",
          },
        ],
        checkPass: [{ validator: validatePass2, trigger: "change" }],
      },
    };
  },

  computed: {
    tabsActiveName() {
      return this.$route.query.tab || "login";
    },
  },
  watch: {},

  methods: {
    ...mapActions({
      login: "user/login",
    }),
    // 登录
    onSubmit() {
      this.$refs.loginFormRef.validate().then(() => {
        this.login(this.loginForm).then(() => {
          this.$router.push({
            path: "/ucenter",
            query: {
              id: "index",
            },
          });
        });

        this.$refs.loginFormRef.resetFields();
      });
    },
    onSubmitRegister() {
      this.$refs.registerFormRef.validate().then(() => {
        ElMessage.success("注册成功！");
        this.$refs.registerFormRef.resetFields();
      });
    },
    handleChange(e) {
      console.log(e);
    },
    tabClickName() {
      this.$refs.loginFormRef.resetFields();
      this.$refs.registerFormRef.resetFields();
    },
  },
};
</script>
<style lang="less" scoped>
@inputHeight: 36px;
.login-box {
  height: 750px;
  padding-top: 140px;
  background: url("../assets/login_bg.png") no-repeat center/cover;
}
.login-content {
  position: relative;
  margin: auto;
  width: 836px;
  height: 440px;
  max-width: 1550px;
  box-shadow: 5px 0px 30px 0px rgba(55, 52, 169, 0.1);
  border-radius: 32px;
  background: url("../assets/login_bg02.png") no-repeat center/cover;
}
.page-tabs {
  position: absolute;
  right: 0;
  top: 0;
  width: 420px;
  margin: auto;
  text-align: left;
}
.login-form {
  padding: 50px 40px;
}
input,
.login-form-password {
  width: 100%;
  height: @inputHeight;
  border-radius: 8px;
  box-sizing: border-box;
}
.login-btn {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
  box-shadow: none;
  border-radius: 24px;
  background-color: #3d4566;
  font-size: 18px;
  color: #fff;
  letter-spacing: 4px;
}
.register-form {
  padding: 5px 30px 10px;
}
.form-item-tips {
  font-size: 12px;
  line-height: 1;
  padding: 2px 0;
}
.item-captcha {
  :deep(.el-input) {
    width: 120px;
  }
  :deep(.el-form-item__error) {
    display: inherit;
  }
}
:deep(.el-form-item__error) {
  display: none;
}
:deep(.el-checkbox__label) {
  a {
    color: inherit;
  }
}
</style>
