import { createRouter, createWebHistory } from "vue-router";
// import Home from '../views/Home.vue'
import AppMain from "../AppMain.vue";
import AppBusiness from "../AppBusiness.vue";
const routes = [
  {
    path: "/",
    name: "",
    component: AppMain,
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/views/Home/index.vue"),
      },
      {
        path: "applyJoin",
        name: "ApplyJoin",
        component: () => import("@/views/ApplyJoin/index.vue"),
      },
      {
        path: "register",
        name: "Register",
        component: () => import("@/views/Register.vue"),
      },
      {
        path: "shopCart",
        name: "ShopCart",
        component: () => import("@/views/ShopCart/index.vue"),
      },
      {
        path: "businessInfo",
        name: "businessInfo",
        component: () => import("@/views/Production/businessInfo.vue"),
      },
      {
        path: "product",
        name: "productDrug",
        component: () => import("@/views/Production/index.vue"),
      },
      {
        path: "ordersubmit",
        name: "orderPage",
        component: () => import("@/views/Production/orderPage.vue"),
      },
      {
        path: "orderSuccess",
        name: "orderSuccess",
        component: () => import("@/views/Production/orderSuccess.vue"),
      },
      {
        path: "ucenter",
        name: "PersonCenter",
        component: () => import("@/views/Person/index.vue"),
      },
      {
        path: "agreement",
        name: "Agreement",
        component: () => import("@/views/Agreement/index.vue"),
      },
      {
        path: "callback",
        name: "Callback",
        component: () => import("@/views/Callback/index.vue"),
      },
    ],
  },
  {
    path: "/business",
    name: "AppBusiness",
    component: AppBusiness,
    children: [
      {
        path: "",
        name: "Business",
        component: () => import("@/views/Business/index.vue"),
      },
      {
        path: "businessManagement",
        name: "businessManagement",
        component: () => import("@/views/Business/businessManagement.vue"),
      },
    ],
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
