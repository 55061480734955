<template>
  <div class="container">
    <div class="page-breadcrumb">
      您当前的位置： <span @click="jumpToHome">首页</span> » 购物车
    </div>
    <div class="order-page-content">
      <h5>填写核对订单信息</h5>
      <ul class="order-info-list">
        <li class="order-info-item">
          <h6>收货人信息</h6>
          <dl class="item-dlist">
            <dt>常用收货地址</dt>
            <dd v-for="(item, i) in userAddress" :key="i">
              <el-radio v-model="order.address" :label="item.id" name="address">
                <span class="address-item-span">{{ item.userNm }}</span>
                <span class="address-item-span">{{ item.provinceNm }}</span>
                <span class="address-item-span">{{ item.cityNm }}</span>
                <span class="address-item-span">{{ item.areaNm }}</span>
                <span class="address-item-span">{{ item.addressDetail }}</span>
                <span class="address-item-span">{{ item.telephone }}</span>
              </el-radio>
              <el-button
                type="text"
                class="order-address-editbtn"
                @click="showAddressModal($event, item)"
                >[修改地址]</el-button
              >
            </dd>
            <dd class="order-address-addbtn">
              <span @click="showAddressModal">添加新地址</span>
            </dd>
          </dl>
        </li>
        <li class="order-info-item">
          <h6>配送方式</h6>
          <table class="item-table" border="0" cellspacing="0">
            <tr>
              <th>
                <el-radio label="快递" v-model="order.deliveType"></el-radio>
              </th>
              <td>直接由第三方物流公司配送</td>
            </tr>
            <tr>
              <th>
                <el-radio label="货到付款" v-model="order.deliveType"></el-radio>
              </th>
              <td>直接由第三方物流公司配送</td>
            </tr>
            <tr>
              <th>指定送货时间：</th>
              <td>
                <el-radio-group v-model="order.deliveTime">
                  <el-radio label="任意"></el-radio>
                  <el-radio label="周一到周五"></el-radio>
                  <el-radio label="周末"></el-radio>
                </el-radio-group>
              </td>
            </tr>
          </table>
        </li>
        <li class="order-info-item">
          <h6>支付方式</h6>
          <table class="item-table" border="0" cellspacing="0">
            <tr>
              <th class="width-360">
                <el-radio v-model="order.deliveMethod" label="预存款"></el-radio>
                <div class="display-flex">
                  <img :src="orderImgSrc" alt="" /> <span>预存款</span>
                </div>
              </th>
              <td></td>
            </tr>
          </table>
        </li>
        <li class="order-info-item">
          <h6>订单附言</h6>
          <table class="item-table">
            <tr>
              <th>订单附言：</th>
              <td><el-input></el-input></td>
            </tr>
          </table>
        </li>
        <li class="order-info-item">
          <h6>购买的商品</h6>
          <el-table
            class="shopcart-table"
            ref="multipleTableRef"
            style="width: 100%"
            empty-text="购物车中空空如也~"
            :data="tableData"
          >
            <el-table-column label="图片" prop="img" width="115" align="center">
              <template #default="scope">
                <img :src="scope.row.img" :alt="scope.row.cDrugNm" />
              </template>
            </el-table-column>
            <el-table-column label="药品名称" prop="cDrugNm" align="center">
              <template #default="scope">
                <span class="td-drugNm-span">{{ scope.row.cDrugNm }}</span>
              </template>
            </el-table-column>
            <el-table-column label="赠送积分" prop="score" width="120" align="center">
              <template #default="scope">
                {{ scope.row.score || 0 }}
              </template>
            </el-table-column>
            <el-table-column label="优惠" prop="discount" width="120" align="center">
              <template #default="scope"> 减￥{{ scope.row.discount || 0 }} </template>
            </el-table-column>
            <el-table-column label="数量" prop="count" width="120" align="center">
              <template #default="scope">
                {{ scope.row.count }}
              </template>
            </el-table-column>
            <el-table-column label="小计" prop="subtotal" width="120" align="center">
              <template #default="scope">
                <span class="td-number-span">￥{{ scope.row.subtotal }}</span>
              </template>
            </el-table-column>
          </el-table>
        </li>
        <li class="order-info-item">
          <h6>结算信息</h6>
          <div class="item-cart-count">
            <div class="cart--count-detail">
              商品总金额：<span class="font-weight">{{ order.totalPrice }}</span
              >优惠券：<span class="font-weight">0</span> 税金：<span class="font-weight"
                >0</span
              >
              运费总计：<span class="font-weight">0</span> 保价：<span class="font-weight"
                >0</span
              >
            </div>
            <p>
              应付总额：<span class="font-price">￥</span
              ><span class="font-price">{{ order.totalPrice }}</span> 元
            </p>
          </div>
        </li>
      </ul>
      <div class="order-button">
        <el-button @click="submitOrder">确定无误，提交订单</el-button>
      </div>
    </div>
  </div>
  <address-modal
    v-model:isShowDialog.sync="isShowAddressModal"
    v-model:addressInfo.sync="addressInfo"
  ></address-modal>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-14 11:50:05
 *@version: V1.0.0
 *@description: 订单提交页面
 *************************************************************************/
import { mapGetters, mapActions } from "vuex";
import AddressModal from "./modules/AddressModal.vue";
import { ElMessage } from "element-plus/lib/components";
export default {
  name: "",
  components: {
    AddressModal,
  },
  props: {},
  data() {
    return {
      order: {
        address: "",
        deliveType: "快递",
        deliveTime: "任意",
        deliveMethod: "",
        totalPrice: 0,
        orderNm: "", //订单编号
        status: "等待付款",
      },
      orderImgSrc: require("../../assets/pay_deposit.gif"),
      isShowAddressModal: false,
      addressInfo: {}, //存放编辑的地址信息
    };
  },
  computed: {
    ...mapGetters(["shopCartList", "unitGoods", "userAddress"]),
    tableData() {
      let type = this.$route.query.type;
      let dataSource = [];
      if (type == "goods") {
        dataSource.push(this.unitGoods);
      } else {
        let selectGoodsCart = localStorage.getItem("selectGoodsCart");
        dataSource = JSON.parse(selectGoodsCart)||[];
      }
      return dataSource;
    },
  },
  watch: {},
  mounted() {
    console.log(this.userAddress);
    this.handleCountPrice();
  },
  methods: {
    ...mapActions({
      setOrderOpts: "user/setOrderOpts",
      clearGoodsCart: "carts/clearGoodsCart",
      calculateCartCount: "carts/calculateCartCount",
    }),
    jumpToHome() {
      this.$router.push({
        path: "/",
      });
    },
    handleCountPrice() {
      this.order.totalPrice = 0;
      for (let i = 0; i < this.tableData.length; i++) {
        const { subtotal } = this.tableData[i];
        console.log(subtotal);
        this.order.totalPrice += subtotal * 1;
      }
      console.log(this.order.totalPrice);
    },
    // 添加收货地址
    showAddressModal(_, $item) {
      this.isShowAddressModal = true;
      this.addressInfo = $item ? { ...$item } : null;
    },
    submitOrder() {
      const { deliveType, deliveMethod, address } = this.order;
      const date = new Date();
      const yy = date.getFullYear();
      const mm = this.toZero(date.getMonth() + 1);
      const dd = this.toZero(date.getDate());
      const h = this.toZero(date.getHours());
      const m = this.toZero(date.getMinutes());
      const s = this.toZero(date.getSeconds());
      const ms = date.getMilliseconds();
      this.order.orderNm = `${yy}${mm}${dd}${h}${m}${s}${ms}`; //订单编号
      this.order.createTime = `${yy}-${mm}-${dd} ${h}:${m}:${s}`; //下单日期
      for (let i = 0; i < this.userAddress.length; i++) {
        if (address == this.userAddress[i].id) {
          this.order.consignee = this.userAddress[i].userNm; //收货人
        }
      }
      if (address) {
        if (deliveType == "快递") {
          if (deliveMethod) {
            // 跳转到订单成功提交页存储订单信息
            this.setOrderOpts(this.order);
            this.clearGoodsCart(); //清空购物车
            this.calculateCartCount(0); //计数
            this.$router.push({
              path: "/orderSuccess",
            });
          } else {
            ElMessage.error("请选择支付方式！");
          }
        } else {
          this.setOrderOpts(this.order);
          this.clearGoodsCart(); //清空购物车
          this.calculateCartCount(0); //计数
          this.$router.push({
            path: "/orderSuccess",
          });
        }

      } else {
        if (this.userAddress.length > 0) {
          ElMessage.error("请选择收货人信息");
        } else {
          ElMessage.error("请添加收货人信息");
        }
      }
    },
    toZero(n) {
      return n > 10 ? n : "0" + n;
    },
  },
};
</script>
<style lang="less" scoped>
.order-page-content {
  margin: 0 auto 15px;
  border: 1px solid #ddd;
  background: #fff;
  text-align: left;
  h5 {
    background: #fff;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #ddd;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    text-indent: 20px;
    font-weight: bold;
  }
  .order-button {
    margin: 20px 20px 20px 0;
    text-align: right;
    :deep(.el-button) {
      background-color: #ff8225;
      min-width: 140px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }
  }
}
.order-info-item {
  padding: 20px;
  h6 {
    border-top: 2px solid #e71f19;
    padding: 10px;
    color: #ff8225;
    font-size: 15px;
    font-weight: bold;
  }
  .item-dlist {
    padding: 10px 20px;
    border: 1px solid rgba(245, 197, 58, 0.3);
    dt {
      border-bottom: 1px solid rgba(245, 197, 58, 0.3);
      font-size: 14px;
      padding: 8px 0;
      margin-bottom: 5px;
    }
    dd {
      line-height: 2;
      font-size: 12px;
      :deep(.el-radio) {
        margin-right: 10px;
      }
    }
    .order-address-editbtn {
      padding: 4px 10px;
    }
  }
  .item-table {
    width: 100%;
    text-align: left;
    border: 1px solid #ddd;
    border-bottom: none;
    font-size: 13px;
    th {
      width: 180px;
      padding: 8px 8px 8px 30px;
      line-height: 1.6;
      border-width: 0 1px 1px 0;
      border-color: #ddd;
      border-style: solid;
      :deep(.el-radio__label) {
        font-weight: 550;
      }
      &.width-360 {
        width: 360px;
        :deep(.el-radio) {
          vertical-align: top;
        }
        .display-flex {
          display: inline-flex;
          align-items: center;
        }
      }
    }
    td {
      padding-left: 10px;
      border-bottom: 1px solid #ddd;
      :deep(.el-input) {
        width: 360px;
      }
    }
  }
  .item-cart-count {
    padding: 20px;
    background-color: rgba(245, 197, 58, 0.06);
    .cart--count-detail {
      font-size: 14px;
      .font-weight {
        font-weight: 550;
        color: #e71f19;
      }
    }
    p {
      text-align: right;
      font-size: 12px;
      .font-price {
        display: inline-block;
        font-size: 16px;
        color: #e71f19;
        vertical-align: middle;
      }
    }
  }
}
.shopcart-table {
  img {
    width: 66px;
    height: 66px;
    padding: 3px;
    border: 1px solid #ddd;
    margin: 10px auto;
  }
  .td-drugNm-span {
    color: #2d64b3;
    cursor: pointer;
  }
  .td-number-span {
    font-weight: bold;
    color: #e71f19;
  }
  :deep(&.el-table) {
    .cell {
      padding: 0 5px;
    }
    .el-table__cell {
      padding: 4px 0;
      font-size: 12px;
      color: #555;
    }
  }
  :deep(.el-button) {
    color: #555;
    font-size: 12px;
  }
}
.address-item-span {
  padding: 0 2px;
}
</style>
