<template>
  <h4 class="center-header">
    <span class="center-theme">我的优惠券</span>
  </h4>
  <el-table :data="dataSource" style="width: 100%" empty-text="暂无数据">
    <el-table-column label="编号" prop="numId">
      <template #default="scope">
        {{ scope.row.numId }}
      </template>
    </el-table-column>
    <el-table-column label="面值" prop="orderNm">
      <template #default="scope">
        {{ scope.row.orderNm }}
      </template>
    </el-table-column>
    <el-table-column label="有效时间" prop="createTime">
      <template #default="scope">
        {{ scope.row.createTime }}
      </template>
    </el-table-column>
    <el-table-column label="状况" prop="status">
      <template #default="scope">
        {{ scope.row.status }}
      </template>
    </el-table-column>
  </el-table>

  <div class="marginTb"></div>
  <h4 class="center-header">
    <span class="center-theme">积分兑换</span>
  </h4>
  <div class="integral-empty">暂无数据</div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-13 15:29:52
 *@version: V1.0.0
 *@description: 积分兑换
 *************************************************************************/
export default {
  name: "RmkRedpacket",
  components: {},
  props: {
    info: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      dataSource: this.info,
    };
  },
  computed: {},
  watch: {
    info: {
      deep: true,
      handler(nval) {
        this.$nextTick(() => {
          this.dataSource = [...nval];
        });
      },
    },
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.marginTb {
  margin-top: 15px;
}
.integral-empty {
  padding: 20px 0;
  color: #909399;
}
</style>
