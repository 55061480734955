<template>
  <section class="page-shopcart">
    <div class="container">
      <div class="page-breadcrumb">
        您当前的位置： <span @click="jumpToPage">首页</span> » 购物车
      </div>
      <h3 class="page-title">
        <span class="color-red">
          <el-icon><shopping-cart /></el-icon>
        </span>
        <span>查看购物车</span>
      </h3>
      <div class="shopcart-list-box">
        <el-table
          class="shopcart-table"
          ref="multipleTableRef"
          style="width: 100%"
          empty-text="购物车中空空如也~"
          :data="shopCartList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="30" />
          <el-table-column label="图片" prop="img" width="115" align="center">
            <template #default="scope">
              <img :src="scope.row.img" :alt="scope.row.cDrugNm" />
            </template>
          </el-table-column>
          <el-table-column label="药品名称" prop="cDrugNm" align="center">
            <template #default="scope">
              <span class="td-drugNm-span">{{ scope.row.cDrugNm }}</span>
            </template>
          </el-table-column>
          <el-table-column label="赠送积分" prop="score" width="120" align="center">
            <template #default="scope">
              {{ scope.row.score || 0 }}
            </template>
          </el-table-column>

          <el-table-column label="单价" prop="retailPrice" width="120" align="center">
            <template #default="scope">
              <span class="td-number-span">￥{{ scope.row.retailPrice }}</span>
            </template>
          </el-table-column>
          <el-table-column label="优惠" prop="discount" width="120" align="center">
            <template #default="scope"> 减￥{{ scope.row.discount || 0 }} </template>
          </el-table-column>
          <el-table-column label="数量" prop="count" width="120" align="center">
            <template #default="scope">
              <el-input-number
                v-model="scope.row.count"
                :min="1"
                :max="10000"
                @change="handleCountChange(scope.$index)"
              />
            </template>
          </el-table-column>
          <el-table-column label="小计" prop="subtotal" width="120" align="center">
            <template #default="scope">
              <span class="td-number-span">￥{{ scope.row.subtotal }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="option" width="120" align="center">
            <template #default="scope">
              <el-button type="text" @click.prevent="deleteRow(scope.$index)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <ul class="settlement-list">
          <li>
            <span>商品总重量:<b>0.00g</b></span>
          </li>
          <li>
            <span
              >商品总金额：￥<b>{{ totalPrice }}</b></span
            >-<span>商品优惠：￥<b>0.00</b></span
            >-<span>促销活动优惠：￥<b>0.00</b></span>
          </li>
          <li>
            <span
              >金额总计（不含运费）：￥<b class="drug-price-total">{{
                totalPrice
              }}</b></span
            >
          </li>
        </ul>
      </div>
      <div class="shopcart-list-footer">
        <ul class="shopcart-option-list">
          <li>
            <el-button class="clear-btn" @click="clearShopCart"
              ><el-icon><close-bold /></el-icon>清空购物车</el-button
            >
          </li>
          <li :class="{ haveProduct: shopCartList.length == 0 }">
            <el-button class="back-btn" @click="goBack"
              ><el-icon><shopping-bag /></el-icon>继续购物</el-button
            >
            <el-button
              class="settlement-btn"
              v-if="shopCartList.length > 0"
              @click="goToOrderPage"
              ><el-icon><postcard /></el-icon>去结算</el-button
            >
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-13 10:49:20
 *@version: V1.0.0
 *@description: 购物车页面
 *************************************************************************/
import { ShoppingCart, CloseBold, ShoppingBag, Postcard } from "@element-plus/icons-vue";
import { mapGetters, mapActions } from "vuex";
import { ElMessage } from "element-plus/lib/components";
export default {
  name: "",
  components: { ShoppingCart, CloseBold, ShoppingBag, Postcard },
  props: {},
  data() {
    return {
      totalPrice: 0,
      selectGoodsCart: [],
    };
  },
  computed: {
    ...mapGetters(["shopCartList", "userName"]),
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapActions({
      addGoodsCart: "carts/addGoodsCart",
      calculateCartCount: "carts/calculateCartCount",
      clearGoodsCart: "carts/clearGoodsCart",
    }),
    handleSelectionChange(data) {
      localStorage.setItem("selectGoodsCart", JSON.stringify(data));
      this.selectGoodsCart = [...data];
      this.totalPrice = 0;
      for (let i = 0; i < data.length; i++) {
        const { subtotal } = data[i];
        this.totalPrice += subtotal * 1;
      }
    },
    // 清空购物车
    clearShopCart() {
      this.clearGoodsCart(); //清空购物车
      this.calculateCartCount(0); //计数
    },
    // 改变数量值计算费用
    handleCountChange(i) {
      const { count, retailPrice } = this.shopCartList[i];
      this.shopCartList[i].subtotal = (count * retailPrice).toFixed(2);
      this.shopCartList[i].type = "shopCart";
      localStorage.setItem("shopCartList", JSON.stringify(this.shopCartList));
      this.calculateCartTotal();
    },
    deleteRow(i) {
      this.shopCartList.splice(i, 1);
      this.calculateCartTotal();
    },
    jumpToPage() {
      this.$router.push({
        path: "/",
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    goToOrderPage() {
      if (this.selectGoodsCart.length > 0) {
        if (this.userName) {
          this.$router.push({
            path: "/ordersubmit",
            query: {
              type: "shopCart",
            },
          });
        } else {
          this.$router.push({
            path: "/register",
            query: {
              tab: "login",
            },
          });
        }
      } else {
        ElMessage.warning("请选择结算商品");
      }
    },
    calculateCartTotal() {
      let totalCount = 0;
      if (this.shopCartList) {
        for (let i = 0; i < this.shopCartList.length; i++) {
          const { count } = this.shopCartList[i];
          totalCount += count;
        }
        this.calculateCartCount(totalCount);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.page-shopcart {
  padding: 10px 2rem 1rem;
}
.page-breadcrumb {
  padding: 20px 0;
  color: #999;
  line-height: 18px;
  text-align: left;
  font-size: 12px;
  span {
    color: #555;
    cursor: pointer;
  }
}
.page-title {
  font-size: 14px;
  color: #222;
  margin-bottom: 10px;
  text-align: left;
  .color-red {
    margin-right: 10px;
    color: #e71f19;
  }
}
.shopcart-list-box {
  border: 1px solid #ebeef5;
  box-shadow: 0 0 1px 1px #ebeef5;
}
.shopcart-table {
  img {
    width: 66px;
    height: 66px;
    padding: 3px;
    border: 1px solid #ddd;
    margin: 10px auto;
  }
  .td-drugNm-span {
    color: #2d64b3;
    cursor: pointer;
  }
  .td-number-span {
    font-weight: bold;
    color: #e71f19;
  }
  :deep(&.el-table) {
    .cell {
      padding: 0 5px;
    }
    .el-table__cell {
      padding: 4px 0;
      font-size: 12px;
      color: #555;
    }
  }
  :deep(.el-button) {
    color: #555;
    font-size: 12px;
  }
}
.settlement-list {
  padding: 10px;
  li {
    line-height: 2;
    text-align: right;
    font-size: 12px;
    color: #555;
    .drug-price-total {
      font-weight: bold;
      font-size: 16px;
      color: #e71f19;
    }
  }
}
.shopcart-option-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  :deep(.el-button) {
    font-size: 16px;
    height: 42px;
    padding: 14px 18px;
    letter-spacing: 1px;
    &.settlement-btn {
      background-color: #003a8e;
      color: #fff;
    }
    .el-icon {
      vertical-align: middle;
      margin-right: 6px;
    }
  }
}
.haveProduct {
  padding-right: 132px;
}
:deep(.el-input-number) {
  width: 110px;
  .el-input__inner {
    padding-right: 30px;
    padding-left: 30px;
  }
}
</style>
