<template>
  <div class="footer-serve">
    <div class="container">
      <div class="serve-flex">
        <dl v-for="(item, i) in serveOpts" :key="i">
          <dt>{{ item.theme }}</dt>
          <dl>
            <a @click="clickLink(item.id)" href="javascript: void(0);">{{ item.desc }}</a>
          </dl>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-12 09:52:36
 *@version: V1.0.0
 *@description: 网站服务信息栏
 *************************************************************************/
export default {
  name: "FooterServe",
  components: {},
  props: {},
  data() {
    return {
      serveOpts: [
        {
          id: 1,
          theme: "入驻服务",
          desc: "商家入驻协议",
        },
        {
          id: 2,
          theme: "购物指南",
          desc: "用户注册协议",
        },
        {
          id: 3,
          theme: "支付帮助",
          desc: "货到付款",
          link: "agreement",
        },
        {
          id: 4,
          theme: "配送帮助",
          desc: "加急快递",
        },
        {
          id: 5,
          theme: "售后服务",
          desc: "退换货流程",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    clickLink(id) {
      this.$router.push({
        name: "Agreement",
        query: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.footer-serve {
  padding: 22px 1.5rem 30px;
}
.serve-flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
  dt {
    color: #646464;
    font-size: 16px;
    font-weight: 700;
    height: 30px;
    line-height: 30px;
    margin-bottom: 6px;
  }
  dl {
    a {
      display: block;
      text-align: left;
      height: 24px;
      line-height: 24px;
      color: #8b8b8b;
      font-size: 12px;
      text-decoration: none;
    }
  }
}
</style>
