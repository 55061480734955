<template>
  <div class="page-main-menu">
    <el-menu
      mode="horizontal"
      :default-active="activeIndex"
      active-text-color="#022a75"
    >
      <template v-for="(item, i) in routeList" :key="i + 1">
        <el-menu-item v-model:key="item.link" :index="item.link">
          <router-link :to="item.link">{{ item.title }}</router-link>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-13 09:52:03
 *@version: V1.0.0
 *@description: 功能描述
 *************************************************************************/
export default {
  name: "PageMenu",
  components: {},
  props: {},
  data() {
    return {
      routeList: [
        {
          link: "/",
          title: "首页",
        },
        {
          link: "/business",
          title: "商家管理",
        },
        {
          link: "/applyJoin",
          title: "申请加盟",
        },
      ],
      activeIndex: "/",
    };
  },

  computed: {},
  watch: {},

  methods: {},
};
</script>
<style lang="less" scoped>
.page-main-menu{
  padding-left: 40px;
  flex: 1 ;
}
:deep(.el-menu-item){
  padding: 0 40px;
  margin: 0 10px;
}
:deep(.el-menu--horizontal){
  >.el-menu-item.is-active{
    border-bottom: none;
  }
}

</style>
