<template>
  <div class="category-list">
    <h4 class="category-list-title">入驻商业</h4>
    <div :class="['category-list-div', { none: isNone }]">
      <dl v-for="(item, i) in goodsBusiness" :key="i">
        <dt>
          <span>{{ item.bName }}</span
          ><i>&gt;</i>
        </dt>
        <dd>
          <el-row :gutter="15">
            <el-col
              :xs="12"
              :sm="12"
              :md="8"
              :lg="6"
              :xl="6"
              v-for="(item, i) in item.goodsOpt"
              :key="i"
            >
              <div class="production-item-box">
                <production-item :info="item"></production-item>
              </div>
            </el-col>
          </el-row>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-19 16:45:55
 *@version: V1.0.0
 *@description: 入驻商业list
 *************************************************************************/
import ProductionItem from "@/views/Production/modules/ProductionItem.vue";
export default {
  name: "CategoryList",
  components: {
    ProductionItem,
  },
  props: {},
  data() {
    return {
      goodsBusiness: [
        {
          id: 21,
          business: require("../assets/d2aw8d3k1d5cl7ceiiiw.jpg"),
          bName: "恩济日用旗舰店",
          goodsOpt: [
            {
              id: 84,
              img: require("../assets/images/EHA004025C.png"),
              name: "Sanita U-ZA 婴幼儿洗衣液",
              cDrugNm: "Sanita U-ZA 婴幼儿洗衣液",
              businessId: 21,
              factPrice: 103,
              retailPrice: 103,
              specification: "1.5L",
              packageUnit: "瓶",
              manufacture: "北京韩美药品有限公司",
              branchName: "恩济日用旗舰店",
              detailSet: [
                "https://baohuo-open-oss-rmk.oss-cn-beijing.aliyuncs.com/rich/15967655083831.jpg",
                "https://baohuo-open-oss-rmk.oss-cn-beijing.aliyuncs.com/rich/15967655216032.jpg",
                "https://baohuo-open-oss-rmk.oss-cn-beijing.aliyuncs.com/rich/15967655335243.jpg",
              ],
            },
            {
              id: 86,
              name: "Ofmom",
              img: require("../assets/images/GWSSDKP.png"),
              cDrugNm: "Sanita U-ZA 婴幼儿柔顺剂补充装",
              businessId: 21,
              factPrice: 51,
              retailPrice: 51,
              specification: "1L",
              packageUnit: "袋",
              manufacture: "北京韩美药品有限公司",
              branchName: "恩济日用旗舰店",
              detailSet: [
                "http://www.ofmom.cn/uploads/image/20211112/1636696853.jpg",
              ],
            },
            {
              id: 150,
              img: require("../assets/images/EOG001007C.png"),
              name: "Sanita U-ZA 婴幼儿奶瓶果蔬清洗液",
              cDrugNm: "Sanita U-ZA 婴幼儿奶瓶果蔬清洗液",
              businessId: 21,
              factPrice: 78,
              retailPrice: 78,
              specification: "500ml",
              packageUnit: "瓶",
              manufacture: "北京韩美药品有限公司",
              branchName: "恩济日用旗舰店",
              detailSet: [
                "http://www.ofmom.cn/uploads/image/20211112/1636702100.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636697255.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636701646.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636698226.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636699105.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636703884.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636705229.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636703113.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636701539.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636695968.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636704239.jpg",
              ],
            },
            {
              id: 160,
              img: require("../assets/images/EFB002030C.png"),
              name: "Sanita U-ZA 婴幼儿玩具餐具清洗剂",
              cDrugNm: "Sanita U-ZA 婴幼儿玩具餐具清洗剂",
              businessId: 21,
              factPrice: 78,
              retailPrice: 78,
              specification: "500ml",
              packageUnit: "瓶",
              manufacture: "北京韩美药品有限公司",
              branchName: "恩济日用旗舰店",
              detailSet: [],
            },
          ],
        },
        {
          id: 12,
          business: require("../assets/0zlsw9io1pvopx1gonyf.jpg"),
          bName: "九州通生活旗舰店",
          goodsOpt: [
            {
              id: 78,
              img: require("../assets/images/DUC001007F.png"),
              name: "婴幼儿牙膏 0-2岁",
              cDrugNm: "婴幼儿牙膏 0-2岁",
              businessId: 12,
              factPrice: 42,
              retailPrice: 42,
              specification: "75g",
              packageUnit: "盒",
              manufacture: "北京韩美药品有限公司",
              branchName: "九州通生活旗舰店",
              detailSet: [],
            },
            {
              id: 79,
              img: require("../assets/images/ENC002002C.png"),
              name: "Sanita-Denti莎卡 婴幼儿牙刷 1阶段",
              cDrugNm: "Sanita-Denti莎卡 婴幼儿牙刷 1阶段",
              factPrice: 28,
              retailPrice: 28,
              businessId: 12,
              specification: "",
              packageUnit: "刷头1.7cm/刷毛高1.0cm/柄长10.5cm",
              manufacture: "北京韩美药品有限公司",
              branchName: "九州通生活旗舰店",
              detailSet: [],
            },
            {
              id: 81,
              img: require("../assets/images/DAB013068D.png"),
              name: "婴幼儿牙膏 2-5岁（三种口味）",
              cDrugNm: "婴幼儿牙膏 2-5岁",
              businessId: 12,
              factPrice: 42,
              retailPrice: 42,
              specification: "75g",
              packageUnit: "盒",
              manufacture: "北京韩美药品有限公司",
              branchName: "九州通生活旗舰店",
              detailSet: [],
            },
            {
              id: 82,
              img: require("../assets/images/HQS016025G.png"),
              name: "儿童牙刷 5岁+",
              cDrugNm: "儿童牙刷 5岁+",
              businessId: 12,
              factPrice: 28,
              retailPrice: 28,
              specification: "1支",
              packageUnit: "套",
              manufacture: "北京韩美药品有限公司",
              branchName: "九州通生活旗舰店",
              detailSet: [],
            },
          ],
        },
        {
          id: 33,
          business: require("../assets/7d1wwd6xmfz7s5n6hj2v.jpg"),
          bName: "佳能达母婴旗舰店",
          goodsOpt: [
            {
              id: 163,
              img: require("../assets/images/EWN027001C.png"),
              name: "仿生舒睡婴儿床",
              cDrugNm: "仿生舒睡婴儿床",
              businessId: 33,
              factPrice: 768,
              retailPrice: 768,
              specification: "78cm*50cm*13cm",
              packageUnit: "0-24个月",
              manufacture: "北京韩美药品有限公司",
              branchName: "佳能达母婴旗舰店",
              detailSet: [],
            },
            {
              id: 164,
              img: require("../assets/images/BUC007007F.png"),
              name: "多功能便携婴儿床",
              cDrugNm: "多功能便携婴儿床",
              businessId: 33,
              factPrice: 688,
              retailPrice: 688,
              specification: "78cm*50cm*13cm",
              packageUnit: "0-24个月",
              manufacture: "北京韩美药品有限公司",
              branchName: "佳能达母婴旗舰店",
              detailSet: [],
            },
            {
              id: 154,
              img: require("../assets/images/ENA005155C.png"),
              name: "哄睡婴儿摇椅",
              cDrugNm: "哄睡婴儿摇椅",
              businessId: 33,
              factPrice: 868,
              retailPrice: 868,
              specification: "78cm*50cm*13cm",
              packageUnit: "0-24个月",
              manufacture: "北京韩美药品有限公司",
              branchName: "佳能达母婴旗舰店",
              detailSet: [],
            },
            {
              id: 149,
              img: require("../assets/images/kfysby.png"),
              name: "HARMAS LIGHT FIT",
              cDrugNm: "纤巧轻舒婴儿背带",
              businessId: 33,
              factPrice: 1680,
              retailPrice: 1680,
              specification: "3-36个月",
              packageUnit: "6-22KG",
              manufacture: "北京韩美药品有限公司",
              branchName: "佳能达母婴旗舰店",
              detailSet: [],
            },
          ],
        },
        {
          id: 52,
          business: require("../assets/bqqgyc6n7y06gmuzis0c.jpg"),
          bName: "明朗生活旗舰店",
          goodsOpt: [
            {
              id: 159,
              img: require("../assets/images/DOE001070C.png"),
              name: "Ofmom Yoom Signature",
              cDrugNm: "婴儿手推车玫瑰金车架闪金色",
              businessId: 52,
              factPrice: 10999,
              retailPrice: 10999,
              specification: "0-22KG",
              packageUnit: "0-36个月",
              manufacture: "北京韩美药品有限公司",
              branchName: "明朗生活旗舰店",
              detailSet: [],
            },
            {
              id: 153,
              img: require("../assets/images/HQS016011G.png"),
              name: "Ofmom Yoom Signature",
              cDrugNm: "婴儿手推车黑色车架珍珠白蜂巢色",
              businessId: 52,
              factPrice: 8999,
              retailPrice: 8999,
              specification: "0-22KG",
              packageUnit: "0-36个月",
              manufacture: "北京韩美药品有限公司",
              branchName: "明朗生活旗舰店",
              detailSet: [],
            },
            {
              id: 157,
              img: require("../assets/images/ILELEYSJ20P.png"),
              name: "Ofmom Yoom Easy Folding",
              cDrugNm: "婴儿手推车黑色车架巴黎粉色",
              businessId: 52,
              factPrice: 2999,
              retailPrice: 2999,
              specification: "0-22KG",
              packageUnit: "0-36个月",
              manufacture: "北京韩美药品有限公司",
              branchName: "明朗生活旗舰店",
              detailSet: [],
            },
            {
              id: 158,
              img: require("../assets/images/DAB007021D.png"),
              name: "Ofmom Yoom Easy Folding",
              cDrugNm: "婴儿手推车黑色车架巴黎白色",
              businessId: 52,
              factPrice: 2999,
              retailPrice: 2999,
              specification: "0-22KG",
              packageUnit: "0-36个月",
              manufacture: "北京韩美药品有限公司",
              branchName: "明朗生活旗舰店",
              detailSet: [],
            },
          ],
        },
      ],
    };
  },
  computed: {
    isNone() {
      return this.$route.name !== "Home";
    },
  },
  watch: {},

  methods: {},
};
</script>
<style lang="less" scoped>
@heightBox: 58px;
@heightDt: 55px;
.category-list {
  width: 240px;
  height: @heightBox;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  position: relative;
  &:hover {
    .category-list-div {
      &.none {
        display: block;
      }
    }
  }
}
.category-list-title {
  height: @heightBox;
  line-height: @heightBox;
  padding-left: 16px;
  font-size: 14px;
  color: #fff;
  background-color: #022a75;
  text-align: left;
}
.category-list-div {
  position: relative;
  height: 492px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  &.none {
    display: none;
  }
  dl {
    vertical-align: top;
    height: @heightDt;
    dt {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      align-items: center;
      height: @heightDt;
      line-height: @heightDt;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
    }
    dd {
      display: none;
      position: absolute;
      left: 240px;
      top: 0;
      padding: 15px;
      width: 955px;
      height: 490px;
      background-color: #f3f3f3;
    }
    &:hover {
      dd {
        display: block;
      }
      dt {
        background-color: #fff;
        color: #555;
      }
    }
  }
}
</style>
