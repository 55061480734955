<template>
  <div class="page-section">
    <div class="container">
      <div class="apply-join-box">
        <h4>申请加盟商户</h4>
        <p>加入我们的电商平台，成为我们的供应商，一起共创美好未来</p>
        <el-form
          class="join-form"
          ref="joinFormRef"
          label-width="120px"
          label-suffix=":"
          :model="joinForm"
          :rules="joinFormRules"
        >
          <el-form-item label="登录用户名" prop="name" required>
            <el-input v-model="joinForm.name" autocomplete="off"></el-input>
            <div class="form-item-error">用户名称（必填）</div>
          </el-form-item>
          <el-form-item label="密码" prop="password" required>
            <el-input
              v-model="joinForm.password"
              type="password"
              autocomplete="off"
            ></el-input>
            <div class="form-item-error">登录密码</div>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass" required>
            <el-input
              v-model="joinForm.checkPass"
              type="password"
              autocomplete="off"
            ></el-input>
            <div class="form-item-error">重复确认密码</div>
          </el-form-item>
          <el-form-item label="商户真实全称" prop="storeName" required>
            <el-input v-model="joinForm.storeName" autocomplete="off"></el-input>
            <div class="form-item-error">公司真实全称</div>
          </el-form-item>
          <el-form-item label="商户资质材料" prop="paperFile">
            <el-upload
              ref="upload"
              class="upload-demo"
              action="https://jsonplaceholder.typicode.com/posts/"
              :limit="1"
              :on-exceed="handleExceed"
              :auto-upload="false"
            >
              <el-button type="primary">选择文件</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="固定电话" prop="phone" required>
            <el-input
              v-model="joinForm.phone"
              autocomplete="off"
              :maxlength="12"
            ></el-input>
            <div class="form-item-error">固定电话联系方式，如：010-88888888</div>
          </el-form-item>
          <el-form-item label="手机号码" prop="telePhone" required>
            <el-input
              v-model="joinForm.telePhone"
              autocomplete="off"
              :maxlength="11"
            ></el-input>
            <div class="form-item-error">移动电话联系方式：如：13000000000</div>
          </el-form-item>
          <el-form-item label="地区">
            <el-select
              v-model="joinForm.province"
              @change="queryCurCityOpts"
              :append-to-body="false"
              placeholder="请选择"
              class="join-item-area"
            >
              <el-option
                v-for="item in provinceOpts"
                :key="item.province"
                :value="item.province"
                :label="item.name"
              >
                {{ item.name }}
              </el-option>
            </el-select>
            <el-select
              v-model="joinForm.city"
              @change="queryCurAreaOpts"
              placeholder="请选择"
              class="join-item-area"
            >
              <el-option
                v-for="item in cityOpts"
                :key="item.code"
                :value="item.city"
                :label="item.name"
                >{{ item.name }}</el-option
              >
            </el-select>
            <el-select
              v-model="joinForm.area"
              placeholder="请选择"
              class="join-item-area"
            >
              <el-option
                v-for="item in areaOpts"
                :key="item.code"
                :value="item.area"
                :label="item.name"
                >{{ item.name }}</el-option
              >
            </el-select>
          </el-form-item>

          <el-form-item label="邮箱" prop="email">
            <el-input v-model="joinForm.email" autocomplete="off"></el-input>
            <div class="form-item-error">电子邮箱联系方式：如：ytash@163.com</div>
          </el-form-item>
          <el-form-item label="详细地址" prop="address">
            <el-input v-model="joinForm.address" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="企业官网" prop="site">
            <el-input v-model="joinForm.site" autocomplete="off"></el-input>
            <div class="form-item-error">填写完整的网址</div>
          </el-form-item>
          <el-form-item class="form-item-button">
            <el-button type="primary" @click="submitForm">申请加盟</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-13 08:48:21
 *@version: V1.0.0
 *@description: 申请加盟
 *************************************************************************/
const { province, city, area } = require("province-city-china/data");
export function validEmail(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  reg.lastIndex = 0;
  return reg.test(email);
}
/**
 * 验证手机号
 * @param {string} mobile
 * @returns {boolean}
 */
export function validMobile(mobile) {
  const reg = /^1(3|4|5|6|7|8|9)[0-9]{9}$/;
  return reg.test(mobile);
}
export default {
  name: "",
  components: {},
  props: {},
  data() {
    const validatePassword = async (rule, value) => {
      if (value !== "") {
        if (value.length < 6) {
          return Promise.reject("密码长度不能少于6位数！");
        } else {
          return Promise.resolve();
        }
      } else {
        return Promise.reject("请输入密码");
      }
    };
    const validatePass2 = async (rule, value) => {
      if (value === "") {
        return Promise.reject("请您再次输入密码");
      } else if (value !== this.joinForm.password) {
        return Promise.reject("两次填写的密码不一致");
      } else {
        return Promise.resolve();
      }
    };
    const validateEmail = async (rule, value) => {
      if (value) {
        if (validEmail(value)) {
          return Promise.resolve();
        } else {
          return Promise.reject("邮箱格式不正确");
        }
      } else {
        return Promise.reject("邮箱不可以为空");
      }
    };
    const validateMobilePhone = async (rule, value) => {
      if (value !== "") {
        if (value.length < 11) {
          return Promise.reject("手机号码需不少于11位");
        } else {
          if (!validMobile(value)) {
            return Promise.reject("请输入正确手机号码");
          } else {
            return Promise.resolve();
          }
        }
      } else {
        return Promise.reject("请输入手机号");
      }
    };
    return {
      joinForm: {
        name: "",
        password: "",
        checkPass: "",
        storeName: "", //商户真实名
        paperFile: "", //资质
        phone: "", //固定电话
        telePhone: "", //移动电话
        email: "",
        province: "", //省市区
        country: "",
        city: "",
        address: "",
        site: "",
      },
      joinFormRules: {
        name: [{ required: true, message: "用户名不可以为空！" }],
        password: [
          {
            required: true,
            validator: validatePassword,
            min: 6,
            trigger: "blur",
            blur: "submit",
          },
        ],
        checkPass: [{ required: true, validator: validatePass2, trigger: "change" }],
        storeName: [{ required: true, message: "商户真实全称不可以为空！" }],
        phone: [{ required: true, message: "固定电话不可以为空！" }],
        telePhone: [
          {
            required: true,
            validator: validateMobilePhone,
            trigger: "blur",
            blur: "change",
          },
        ],
        email: [{ required: true, validator: validateEmail }],
      },
      provinceOpts: province,
      city,
      area,
      cityOpts: [],
      areaOpts: [],
    };
  },

  computed: {},
  watch: {},

  methods: {
    handleExceed() {},
    submitForm() {
      this.$refs.joinFormRef
        .validate()
        .then((r) => {
          this.$router.push({
            path: "/callback",
            query: {
              type: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    initQueryCityOpts() {
      const { province } = this.joinForm;
      for (let i = 0; i < this.city.length; i++) {
        const item = this.city[i];
        if (province == item.province) {
          this.cityOpts.push(item);
        }
      }
      if (province && this.cityOpts.length == 0) {
        this.cityOpts[0] = {
          code: `${province}0100`,
          name: "市辖区",
          province: province,
          city: "01",
        };
      }
    },
    // change查询市选项
    queryCurCityOpts() {
      this.cityOpts = [];
      this.areaOpts = [];
      this.joinForm.city = "";
      this.joinForm.area = "";
      this.initQueryCityOpts();
    },
    initQueryAreaOPts() {
      const { province, city } = this.joinForm;
      for (let i = 0; i < this.area.length; i++) {
        const item = this.area[i];
        if (province == item.province && city == item.city) {
          this.areaOpts.push(item);
        }
      }
    },
    // change查询区选项
    queryCurAreaOpts() {
      this.areaOpts = [];
      this.joinForm.area = "";
      this.initQueryAreaOPts();
    },
  },
};
</script>
<style lang="less" scoped>
.page-section {
  background-color: #fff;
}
.apply-join-box {
  h4 {
    margin-bottom: 15px;
    font-size: 18px;
  }
  p {
    font-size: 12px;
    color: #999;
  }
}
.join-form {
  width: 800px;
  padding: 20px;
  margin: 10px auto 20px;
  background-color: #f0f2f5;
  border-radius: 10px;
}
:deep(.el-form-item__content) {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  .el-input {
    width: 400px;
  }
}
.form-item-error {
  padding-left: 12px;
  font-size: 12px;
}
.join-item-area {
  width: 130px;
  margin-right: 6px;
  :deep(.el-input) {
    width: 100%;
  }
}
.form-item-button {
  :deep(&.el-form-item--default) {
    margin-bottom: 0;
  }
  :deep(.el-form-item__content) {
    padding-left: 120px;
  }
  :deep(.el-button) {
    width: 120px;
    height: 42px;
    letter-spacing: 1px;
    font-size: 16px;
  }
}
</style>
