<template>
  <el-container>
    <el-header class="platform-header">
      <!-- 顶部欢迎您 -->
      <page-header></page-header>
      <!-- logo与search -->
      <div class="header-search-wrapper">
        <div class="container">
          <el-row class="logo-search-row">
            <el-col :span="24">
              <page-logo></page-logo>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-header>
    <el-main>
      <router-view></router-view>
    </el-main>
    <el-footer>
      <footer-serve></footer-serve>
      <footer-copyright></footer-copyright>
    </el-footer>
  </el-container>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2021-11-24 14:05:55
 *@version: V1.0.0
 *@description: 入驻商家主框架layout
 *************************************************************************/
import PageHeader from "@/components/PageHeader.vue";
import PageLogo from "@/components/PageLogo.vue";
import FooterCopyright from "@/components/FooterCopyright.vue";
import FooterServe from "@/components/FooterServe.vue";
export default {
  name: "App",
  components: {
    PageHeader,
    PageLogo,
    FooterCopyright,
    FooterServe,
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>
<style lang="less" scoped>
:deep(.el-header),
:deep(.el-main),
:deep(.el-footer) {
  height: auto;
  padding: 0;
}
:deep(.el-footer) {
  background-color: #ededed;
}
.header-search-wrapper {
  height: 100px;
  padding: 0 1.5rem;
}
.logo-search-row {
  padding-top: 20px;
  align-items: center;
}
</style>
