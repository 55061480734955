<template>
  <div>
    <el-card shadow="hover">
      <div class="card-avatar">
        <a class="card-avatar-link">
          <img :src="defaultImg" alt="头像" width="64" height="64" />
        </a>
      </div>
      <div class="card-info-box">
        <div class="card-info">
          <p>Hi~你好!</p>
          <div class="card-button display-flex" v-if="userName.length == 0">
            <el-button round @click="jumpToPage('login')">登录</el-button>
            <el-button round @click="jumpToPage('register')">注册</el-button>
          </div>
        </div>
      </div>
      <div class="card-vip-list">
        <ul class="display-flex">
          <li v-for="(item, i) in vipList" :key="i">
            <div class="vip-item-content">
              <i :class="['icon', 'icon-' + i, item.vipClass]"></i>
              <p>{{ item.desc }}</p>
            </div>
          </li>
        </ul>
      </div>
    </el-card>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-12 10:34:54
 *@version: V1.0.0
 *@description: 功能描述
 *************************************************************************/
import { mapGetters } from "vuex";
export default {
  name: "PersonCard",
  components: {},
  props: {},
  data() {
    return {
      defaultImg: require("../../../assets/user_ico.gif"),
      vipList: [
        {
          desc: "买家保障",
          vipClass: "heartbeat",
        },
        {
          desc: "商家认证",
          vipClass: "heartbeat",
        },
        {
          desc: "安全交易",
          vipClass: "heartbeat",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["userName"]),
  },
  watch: {},
  methods: {
    jumpToPage($query) {
      this.$router.push({
        path: "/register",
        query: {
          tab: $query,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
:deep(.el-card__body) {
  padding: 20px 0;
}
.display-flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.card-avatar {
  position: absolute;
  top: -26px;
  left: 0;
  right: 0;
  margin: auto;
  width: 64px;
  height: 64px;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  .card-avatar-link {
    display: block;
    width: 64px;
    height: 64px;
    overflow: hidden;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
}
.card-info-box {
  margin-top: 45px;
  .card-info {
    padding-bottom: 30px;
    color: #999;
    line-height: 20px;
    .card-button {
      margin-top: 14px;
      :deep(.el-button) {
        width: 68px;
        height: 24px;
        margin: 0 5px;
        font-size: 12px;
        border: 1px solid #212c91;
        background-color: #fff;
        color: #212c91;
      }
    }
  }
}

.card-vip-list {
  box-shadow: 0 -4px 4px -5px rgba(9, 2, 4, 20%);
  ul {
    border-top: 1px solid #fafafa;
    border-bottom: 1px dashed #e5e5e5;
    text-align: center;
    font-size: 12px;
  }
  .vip-item-content {
    width: 62px;
    padding: 12px 0;
    color: #666;

    .icon {
      display: inline-block;
      width: 32px;
      height: 32px;
      font-size: 18px;
      line-height: 32px;
      border-radius: 50%;
      color: #fff;
      &::before {
        content: "";
        position: relative;
        top: 6px;
        display: block;
        margin: auto;
        width: 24px;
        height: 24px;
        background-image: url("../../../assets/css_sprites.png");
        background-repeat: no-repeat;
      }
      &.icon-0 {
        background-color: #ff9b1b;
        &::before {
          background-position: -39px -5px;
        }
      }
      &.icon-1 {
        background-color: #52a6ff;
        &::before {
          background-position: -5px -39px;
        }
      }
      &.icon-2 {
        background-color: #57c15b;
        &::before {
          background-position: -5px -5px;
        }
      }
    }
    P {
      padding-top: 8px;
      line-height: 16px;
      font-size: 12px;
      overflow: hidden;
    }
  }
}
</style>
