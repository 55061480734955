<template>
  <div class="advantage-box">
    <div class="container advantage-img">
      <img :src="advantageSrc" alt="" />
    </div>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-12 09:44:30
 *@version: V1.0.0
 *@description: 底部优势
 *************************************************************************/
export default {
  name: "PageAdvantage",
  components: {},
  props: {},
  data() {
    return {
      advantageSrc: require("../assets/advantage-img.png"),
    };
  },

  computed: {},
  watch: {},

  methods: {},
};
</script>
<style lang="less" scoped>
.advantage-box{
  padding: 0 1.5rem;
}
.advantage-img {
  padding: 30px 0;
  border-bottom: 1px solid #ededed ;
}
</style>
