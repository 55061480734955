<template>
  <div class="display-flex">
    <img :src="store.img" :alt="store.name" />
    <ul class="manufacture-info-list">
      <li>店铺名称：{{ store.name }}</li>
      <li>客服电话：{{ store.phone }}</li>
      <li>商家地址：{{ store.address }}</li>
      <li>店铺等级：{{ store.level }}</li>
      <li>服务范围：{{ store.range }}</li>
      <li>工作时间：{{ store.workHour }}</li>
      <li>发货速度：{{ store.deliverySpeed }}</li>
    </ul>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-14 09:48:34
 *@version: V1.0.0
 *@description: 功能描述
 *************************************************************************/
export default {
  name: "ManufactureInfo",
  components: {},
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      storeOpt: [
        {
          link: "",
          id: 12,
          img: require("../../../assets/grvi0o06dybylgc321gx.jpg"),
          name: "九州通生活旗舰店",
          phone: "400-160-0988",
          workHour: "9:00-18:00",
          address: "中国湖北省武汉市汉阳区龙阳大道特8号",
          level: "钻石",
          range: "全国",
          deliverySpeed: "5 分",
        },
        {
          link: "",
          id: 21,
          img: require("../../../assets/zhfteivlzjbkdng2i1pl.png"),
          name: "恩济日用旗舰店",
          phone: "0379-63005372",
          workHour: "9:00-18:00",
          address: "洛阳空港产业集聚区龙翔西路1号（孟津县麻屯镇卢村）",
          level: "钻石",
          range: "全国",
          deliverySpeed: "5 分",
        },
        {
          link: "",
          id: 52,
          img: require("../../../assets/kc4cy6qge4f4r82nyg3h.png"),
          name: "明朗生活旗舰店",
          phone: "0571-87177091",
          workHour: "9:00-18:00",
          address: "杭州市上城区雷霆路60号长城大厦11、12楼",
          level: "钻石",
          range: "全国",
          deliverySpeed: "5 分",
        },
        {
          link: "",
          id: 33,
          img: require("../../../assets/0g9dag60igf5an93zkjh.jpg"),
          name: "佳能达母婴旗舰店",
          phone: "",
          workHour: "9:00-18:00",
          address: "四川成都金牛古柏开发区古靖路20号",
          level: "钻石",
          range: "全国",
          deliverySpeed: "5 分",
        },
      ],
      store: {},
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.handleComputedId();
  },
  methods: {
    handleComputedId() {
      for (let i = 0; i < this.storeOpt.length; i++) {
        const item = this.storeOpt[i];
        if (item.id == this.id) {
          this.store = { ...item };
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.display-flex {
  display: flex;
  align-items: center;
  padding: 20px 0;
  img {
    width: 200px;
    height: 200px;
  }
}
.manufacture-info-list {
  display: grid;
  grid-template-columns: 1.1fr 1fr 1.3fr;
  text-align: left;
  padding-left: 15px;
  flex: auto;
  li {
    padding: 20px 0;
    font-size: 12px;
    color: #555;
  }
}
</style>
