<template>
  <div class="container">
    <el-row :gutter="15">
      <el-col :span="6">
        <div class="business-img">
          <img :src="opts.business" :alt="opts.bName" @click="jumpToBusiness(opts.id)" />
        </div>
      </el-col>
      <el-col :span="18">
        <el-row class="floor-goods-row" :gutter="15">
          <el-col
            v-for="(info, i) in opts.goodsOpt"
            :key="i"
            :xs="12"
            :sm="12"
            :md="8"
            :lg="8"
            :xl="8"
          >
            <div class="production-item" @click="jumpToPage(info.id)">
              <div class="production-img">
                <img :src="info.img" :alt="info.cDrugNm" />
              </div>
              <div class="production-slide">
                <ul class="display-flex">
                  <li>零售价￥{{ info.retailPrice }}</li>
                </ul>
                <p class="production-title">
                  <span class="production-name">{{ info.cDrugNm }}</span>
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-12 14:00:46
 *@version: V1.0.0
 *@description: 商业公司药品list
 *************************************************************************/
export default {
  name: "FloorGoods",
  components: {},
  props: {
    goods: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      opts: this.goods,
    };
  },
  computed: {},
  watch: {
    goods: {
      deep: true,
      handler(nVal) {
        this.$nextTick(() => {
          this.opts = { ...nVal };
        });
      },
    },
  },
  methods: {
    jumpToPage($id) {
      const productPage = this.$router.resolve({
        path: "/product",
        query: {
          id: $id,
        },
      });
      window.open(productPage.href, "_blank");
    },
    jumpToBusiness($id) {
      const businessPage = this.$router.resolve({
        path: "/businessInfo",
        query: {
          id: $id,
        },
      });
      window.open(businessPage.href, "_blank");
    },
  },
};
</script>
<style lang="less" scoped>
.business-img {
  width: 100%;
  img {
    width: 100%;
    border-radius: 12px;
    cursor: pointer;
    overflow: hidden;
  }
}
.display-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 26px;
  li {
    font-size: 12px;
  }
}
.production-item {
  position: relative;
  margin-bottom: 15px;
  height: 266px;
  transition: 0.5s;
  cursor: pointer;
  overflow: hidden;
  .production-img {
    background-color: #fff;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      transition: 0.5s;
    }
  }
  &:hover {
    .production-img {
      img {
        transform: scale(1.1);
      }
    }
    top: 2px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 30%);
  }
}

.production-slide {
  position: absolute;
  width: 100%;
  height: 62px;
  padding: 6px 16px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.5s ease-in-out;
  background-color: rgba(2, 42, 117, 13%);
  overflow: hidden;
}
.production-title {
  text-align: left;
  transition: all 0.3s ease-in-out;
  span {
    color: #000;
    font-size: 16px;
  }
}
.floor-goods-row {
  :deep(.el-col) {
    &:nth-of-type(3n + 1) {
      .production-item {
        margin-bottom: 0;
      }
    }
  }
}
</style>
