<template>
  <div class="">
    <div class="container">
      <el-row justify="start" class="person-center-row">
        <el-col class="left-col">
          <aside class="person-center-asider">
            <h4><span @click="jumpToPage">我的商城</span></h4>
            <template v-for="(info, i) in asideOpts" :key="i">
              <nav-aside :info="info"></nav-aside>
            </template>
          </aside>
        </el-col>
        <el-col class="right-col">
          <section class="person-center-content">
            <!-- 我的商城 -->
            <div ref="index" v-if="showSect == 'index'">
              <div class="person-flex">
                <div class="person-img">
                  <img :src="defaultImg" />
                </div>
                <div class="person-info">
                  <h5>{{userName}} ,欢迎您</h5>
                  <ul class="person-center-grid">
                    <li v-for="(item, i) in perInfoOpts" :key="i">
                      <span class="grid-key">{{ item.key }}：</span>
                      <span class="grid-weight">{{ item.value }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <rmk-order ref="order"></rmk-order>
            </div>
            <!-- 我的订单 -->
            <rmk-order ref="order" v-if="showSect == 'order'"></rmk-order>
            <!-- 我的积分 -->
            <rmk-integral ref="integral" v-if="showSect == 'integral'"></rmk-integral>
            <!-- 我的优惠券 -->
            <rmk-redpacket ref="redpacket" v-if="showSect == 'redpacket'"></rmk-redpacket>
            <!-- 售后服务 -->
            <rmk-refunds ref="refunds" v-if="showSect == 'refunds'"></rmk-refunds>
            <!-- 站点建议 -->
            <rmk-complain ref="complain" v-if="showSect == 'complain'"></rmk-complain>
            <!-- 商品咨询 -->
            <rmk-consult ref="consult" v-if="showSect == 'consult'"></rmk-consult>
            <!-- 商品评价 -->
            <rmk-evaluation
              ref="evaluation"
              v-if="showSect == 'evaluation'"
            ></rmk-evaluation>
          </section>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-13 10:50:00
 *@version: V1.0.0
 *@description: 个人中心页面
 *************************************************************************/
import NavAside from "./modules/NavAside.vue";
import RmkIntegral from "./modules/RmkIntegral.vue";
import RmkOrder from "./modules/RmkOrder.vue";
import RmkRedpacket from "./modules/RmkRedpacket.vue";
import RmkRefunds from "./modules/RmkRefunds.vue";
import RmkConsult from "./modules/RmkConsult.vue";
import RmkComplain from "./modules/RmkComplain.vue";
import RmkEvaluation from "./modules/RmkEvaluation.vue";
import { mapGetters } from "vuex";
export default {
  name: "",
  components: {
    NavAside,
    RmkOrder,
    RmkIntegral,
    RmkRedpacket,
    RmkRefunds,
    RmkConsult,
    RmkComplain,
    RmkEvaluation,
  },
  props: {},
  data() {
    return {
      asideOpts: [
        {
          title: "交易记录",
          option: [
            {
              menuNm: "我的订单",
              key: "order",
            },
            {
              menuNm: "我的积分",
              key: "integral",
            },
            {
              menuNm: "我的优惠券",
              key: "redpacket",
            },
          ],
        },
        {
          title: "服务中心",
          option: [
            {
              menuNm: "售后服务",
              key: "refunds",
            },
            {
              menuNm: "站点建议",
              key: "complain",
            },
            {
              menuNm: "商品咨询",
              key: "consult",
            },
            {
              menuNm: "商品评价",
              key: "evaluation",
            },
          ],
        },
        // {
        //   title: "应用",
        //   option: [
        //     {
        //       menuNm: "短信息",
        //       key: "message",
        //     },
        //     {
        //       menuNm: "收藏夹",
        //       key: "favorite",
        //     },
        //   ],
        // },
        // {
        //   title: "账户资金",
        //   option: [
        //     {
        //       menuNm: "帐户余额",
        //       key: "account_log",
        //     },
        //     {
        //       menuNm: "在线充值",
        //       key: "online_recharge",
        //     },
        //   ],
        // },
        // {
        //   title: "个人设置",
        //   option: [
        //     {
        //       menuNm: "地址管理",
        //       key: "address",
        //     },
        //     {
        //       menuNm: "个人资料",
        //       key: "info",
        //     },
        //     {
        //       menuNm: "修改密码",
        //       key: "password",
        //     },
        //     {
        //       menuNm: "发票管理",
        //       key: "invoice",
        //     },
        //   ],
        // },
      ],
      showSect: this.$route.query.id,
      defaultImg: require("../../assets/user_ico.gif"),
      perInfoOpts: [
        {
          key: "总积分",
          value: "0 分",
        },
        {
          key: "交易总数量",
          value: "0 笔",
        },
        {
          key: "总消费额",
          value: "￥0.00",
        },
        {
          key: "预存款余额",
          value: "￥0.00",
        },
        {
          key: "优惠券",
          value: "0 张",
        },
        {
          key: "经验值",
          value: "0",
        },
        {
          key: "待评价商品",
          value: "(0)",
        },
        {
          key: "待付款订单",
          value: "(0)",
        },
        {
          key: "待确认收货",
          value: "(0)",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["userName"]),
  },
  watch: {},
  methods: {
    jumpToPage() {
      this.$router.push({
        path: "/ucenter",
        query: {
          id: "index",
        },
      });
      this.$route.query.id = "index";
      this.showSect = "index";
    },
  },
};
</script>
<style lang="less" scoped>
.person-center-row {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  .left-col {
    width: 240px;
    flex: 1;
  }
}

.person-center-asider {
  width: 240px;
  h4 {
    padding: 28px 0 22px;
    line-height: 20px;
    font-weight: normal;
    text-align: left;
    span {
      padding-left: 29px;
      font-size: 18px;
      color: #333;
      cursor: pointer;
    }
  }
}
.person-center-content {
  padding-left: 15px;
  :deep(.center-header) {
    height: 40px;
    line-height: 40px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  :deep(.center-theme) {
    display: inline-block;
    font-size: 14px;
    background: #f0f0f0;
    padding: 0 15px;
    height: 40px;
    line-height: 40px;
  }
}
.person-flex {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 25px;
  margin-bottom: 25px;
  .person-img {
    width: 108px;
    height: 108px;
    img {
      border: 1px solid #dedede;
      border-radius: 50%;
    }
  }
  .person-info {
    padding-left: 25px;
    flex: auto;
    text-align: left;
    h5 {
      font-size: 24px;
      padding: 1px 0 13px;
      font-weight: 400;
    }
    .person-center-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      color: #555;
      font-size: 14px;
      li {
        line-height: 24px;
        .grid-weight {
          font-weight: 550;
          color: #003a8e;
        }
      }
    }
  }
}
</style>
