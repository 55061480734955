/****************
 *@author: niubj
 *@date: 2021-09-15 14:39:54
 *@version: V1.0.0
 *@description: 页面功能描述
 *************************************************************************/
const getters = {
  shopCartList: (state) => state.carts.shopCartList, //购物车数据
  cartTotalCount: (state) => state.carts.cartTotalCount, //购物车商品总数
  unitGoods: (state) => state.carts.unitGoods, //单个商品订单
  userName: (state) => state.user.userName,// 用户i姓名
  userAddress: (state) => state.user.userAddress, //用户添加的收货地址
  orderOpts: (state) => state.user.orderOpts, //订单数据
  rmkUnitOrder: (state) => state.user.rmkUnitOrder, //单个订单
};
export default getters;
