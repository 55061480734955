<template>
  <el-container>
    <el-header class="platform-header">
      <!-- 顶部欢迎您 -->
      <page-header></page-header>
      <!-- logo与search -->
      <div class="header-search-wrapper">
        <div class="container">
          <el-row class="logo-search-row">
            <el-col :span="10">
              <page-logo></page-logo>
            </el-col>
            <el-col :span="14">
              <header-search></header-search>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 菜单栏 -->
      <div>
        <div class="container platform-page-menu">
          <!--  -->
          <category-list></category-list>
          <page-menu></page-menu>
        </div>
      </div>
    </el-header>
    <el-main>
      <router-view></router-view>
    </el-main>
    <el-footer>
      <page-advantage></page-advantage>
      <footer-serve></footer-serve>
      <footer-copyright></footer-copyright>
    </el-footer>
  </el-container>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2021-11-24 14:05:55
 *@version: V1.0.0
 *@description: 主框架layout
 *************************************************************************/

import PageHeader from "@/components/PageHeader.vue";
import PageLogo from "@/components/PageLogo.vue";
import HeaderSearch from "@/components/HeaderSearch.vue";
import PageMenu from "@/components/PageMenu.vue";
import PageAdvantage from "@/components/PageAdvantage.vue";
import FooterCopyright from "@/components/FooterCopyright.vue";
import FooterServe from "@/components/FooterServe.vue";
import CategoryList from "@/components/CategoryList.vue";

export default {
  name: "App",
  components: {
    PageHeader,
    PageLogo,
    PageMenu,
    HeaderSearch,
    PageAdvantage,
    FooterCopyright,
    FooterServe,
    CategoryList,
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {
  },
  methods: {},
};
</script>
<style lang="less" scoped>
:deep(.el-header),
:deep(.el-main),
:deep(.el-footer) {
  height: auto;
  padding: 0;
}
:deep(.el-footer) {
  border-top: 2px solid #ededed;
}
:deep(.el-menu--horizontal){
  border-bottom: none;
}
.header-search-wrapper {
  height: 100px;
  padding: 0 1.5rem;
}
.logo-search-row {
  padding-top: 20px;
  align-items: center;
}
.platform-page-menu{
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
}
</style>
