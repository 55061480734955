<template>
  <div class="">
    <div class="container">
      <el-row class="product-top-row">
        <el-col class="product-top-left" :span="10">
          <div class="product-top-img">
            <img-zoom
              :src="drugInfo.img"
              width="482.5"
              height="482.5"
              :bigsrc="drugInfo.img"
              :configs="configs"
            ></img-zoom>
          </div>
        </el-col>
        <el-col class="product-top-right" :span="14">
          <div class="product-info">
            <h6 class="product-name">{{ drugInfo.cDrugNm }}</h6>
            <div class="drug-info-dlist">
              <dl>
                <dt>名称：</dt>
                <dd>{{ drugInfo.cDrugNm }}</dd>
              </dl>
              <dl>
                <dt>生产厂商：</dt>
                <dd>{{ manufactName }}</dd>
              </dl>
              <dl v-if="false">
                <dt>包装规格：</dt>
                <dd>{{ `${drugInfo.specification} / ${drugInfo.packageUnit}` }}</dd>
              </dl>
              <dl>
                <dt>库存：</dt>
                <dd>现货{{ parseInt(87 * (Math.random() + 1)) }}</dd>
              </dl>
              <dl>
                <dt>销量：</dt>
                <dd>{{ parseInt(1800 * (Math.random() + 1)) }}</dd>
              </dl>
            </div>
            <div class="drug-retail-price">
              <dl>
                <dt>零售价格：</dt>
                <dd>
                  ￥<span class="font-weight">{{ drugInfo.retailPrice }}</span>
                </dd>
              </dl>
            </div>
            <div class="drug-freight">
              <dl>
                <dt>运费:</dt>
                <dd>
                  快递：￥20（直接由第三方物流公司配送）
                  货到付款：￥20（直接由第三方物流公司配送）
                </dd>
              </dl>
            </div>
            <div class="drug-pay">
              <dl>
                <dt>购买数量:</dt>
                <dd>
                  <el-input-number v-model="drugCount" :min="1" :max="10000" />
                </dd>
              </dl>
            </div>
            <div class="drug-button">
              <el-button class="purchase-button" @click="orderSubmit"
                ><el-icon><shopping-cart /></el-icon>立即购买</el-button
              >
              <el-button class="join-button" @click="joinShopCart">
                <el-icon><shopping-cart-full /></el-icon>加入购物车</el-button
              >
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- 商家信息 -->
      <div>
        <h3 class="recommond-tab-title">
          <div class="recommond-tab-div">
            <span>商家信息</span>
          </div>
        </h3>
        <manufacture-info :id="drugInfo.businessId"></manufacture-info>
      </div>
      <div>
        <el-tabs v-model="tabsActiveName">
          <el-tab-pane label="商品详情" name="productDetail">
            <h4 class="product-tabs-theme">商品详情：</h4>
            <ul class="product-tabs-ulist">
              <li>商品名称：{{ drugInfo.cDrugNm }}</li>
              <li>规格：{{ drugInfo.specification }}</li>
              <li>生产厂家：{{ drugInfo.manufacture }}</li>
              <li>关键词：</li>
              <li>商品编码：{{ drugInfo.prodBarcode }}</li>
              <li v-if="false">包装：{{ drugInfo.approvalNo }}</li>
            </ul>
            <h4 class="product-tabs-theme">
              商品说明书 (商品包装和说明书以实物为准，平台内容仅供参考)
            </h4>
            <p v-for="(img, i) in drugInfo.detailSet" :key="i" style="font-size: 0">
              <img :src="img" />
            </p>
            <pre
              v-if="drugInfo.table"
              v-html="drugInfo.table"
              class="pre-druginfo-table"
            ></pre>
          </el-tab-pane>
          <el-tab-pane label="顾客评价(0)" name="productRecommend"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-14 08:43:30
 *@version: V1.0.0
 *@description: 商品详情页
 *************************************************************************/
import { ShoppingCartFull, ShoppingCart } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import ManufactureInfo from "./modules/ManufactureInfo.vue";
import { mapGetters, mapActions } from "vuex";
import imgZoom from "vue2.0-zoom";
export default {
  name: "productDrug",
  components: { ShoppingCartFull, ShoppingCart, ManufactureInfo, imgZoom },
  props: {},
  data() {
    return {
      id: this.$route.query.id,
      drugOpts: [
        {
          id: 84,
          img: require("../../assets/images/EHA004025C.png"),
          name: "Sanita U-ZA 婴幼儿洗衣液",
          cDrugNm: "Sanita U-ZA 婴幼儿洗衣液",
          businessId: 21,
          factPrice: 103,
          retailPrice: 103,
          specification: "1.5L",
          packageUnit: "瓶",
          manufacture: "北京韩美药品有限公司",
          branchName: "恩济日用旗舰店",
          approvalNo: "",
          prodBarcode: "6932721510916",
          prodValidTime: " 24 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211112/1636702100.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636697255.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636701646.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636698226.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636699105.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636703884.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636705229.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636703113.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636701539.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636695968.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636704239.jpg",
          ],
          table: "",
        },
        {
          id: 85,
          img: require("../../assets/images/EOG001009C.png"),
          name: "Sanita U-ZA 婴幼儿洗衣液补充装",
          cDrugNm: "Sanita U-ZA 婴幼儿洗衣液补充装",
          businessId: 21,
          factPrice: 61,
          retailPrice: 61,
          specification: "1L",
          packageUnit: "袋",
          manufacture: "北京韩美药品有限公司",
          branchName: "恩济日用旗舰店",
          approvalNo: "袋",
          prodBarcode: "6932721511128",
          prodValidTime: " 24 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211112/1636702100.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636697255.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636701646.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636698226.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636699105.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636703884.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636705229.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636703113.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636701539.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636695968.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636704239.jpg",
          ],
          table: "",
        },
        {
          id: 86,
          img: require("../../assets/images/GWSSDKP.png"),
          name: "Ofmom",
          cDrugNm: "Sanita U-ZA 婴幼儿柔顺剂补充装",
          businessId: 21,
          factPrice: 51,
          retailPrice: 51,
          specification: "1L",
          packageUnit: "盒",
          manufacture: "北京韩美药品有限公司",
          branchName: "恩济日用旗舰店",
          approvalNo: "袋",
          prodBarcode: "6932721511128",
          prodValidTime: " 24 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211112/1636696853.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636705415.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636703499.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636700591.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636698013.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636700514.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636697791.jpg",
          ],
          table: "",
        },
        {
          id: 150,
          img: require("../../assets/images/EOG001007C.png"),
          name: "Sanita U-ZA 婴幼儿奶瓶果蔬清洗液",
          cDrugNm: "Sanita U-ZA 婴幼儿奶瓶果蔬清洗液",
          businessId: 21,
          factPrice: 78,
          retailPrice: 78,
          specification: "500ml",
          packageUnit: "瓶",
          manufacture: "北京韩美药品有限公司",
          branchName: "恩济日用旗舰店",
          approvalNo: "瓶",
          prodBarcode: "6932721511128",
          prodValidTime: " 24 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211112/1636706524.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636706720.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636705966.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636703125.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636705719.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636704204.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636705836.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636699999.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636703604.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636706160.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636706717.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636699917.jpg",
          ],
          table: "",
        },
        {
          id: 160,
          img: require("../../assets/images/EFB002030C.png"),
          name: "Sanita U-ZA 婴幼儿玩具餐具清洗剂",
          cDrugNm: "Sanita U-ZA 婴幼儿玩具餐具清洗剂",
          businessId: 21,
          factPrice: 78,
          retailPrice: 78,
          specification: "500ml",
          packageUnit: "瓶",
          manufacture: "北京韩美药品有限公司",
          branchName: "恩济日用旗舰店",
          approvalNo: "",
          prodBarcode: "6932721511081",
          prodValidTime: " 24 月",
          detailSet: ["http://www.ofmom.cn/uploads/image/20211112/1636701242.jpg"],
          table: "",
        },
        {
          id: 151,
          img: require("../../assets/images/ENC002003C.png"),
          name: "Sanita U-ZA 紫外线消毒柜",
          cDrugNm: "Sanita U-ZA 紫外线消毒柜",
          businessId: 21,
          factPrice: 2680,
          retailPrice: 2680,
          specification: "352*379*424mm",
          packageUnit: "6.53KG/23.5L",
          manufacture: "北京韩美药品有限公司",
          branchName: "恩济日用旗舰店",
          approvalNo: "",
          prodBarcode: "6932721511173",
          prodValidTime: " 24 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211112/1636705033.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636710271.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636713531.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636712084.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636713243.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636711504.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636708284.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636711824.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636705316.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636712081.jpg",
            "http://www.ofmom.cn/uploads/image/20211112/1636704836.jpg",
          ],
          table: "",
        },
        {
          id: 152,
          img: require("../../assets/images/BOA012001C.png"),
          name: "益动",
          cDrugNm: "马来酸多潘立酮片",
          businessId: 21,
          factPrice: 13.5,
          retailPrice: 38,
          specification: "30粒",
          packageUnit: "盒",
          manufacture: "北京韩美药品有限公司",
          branchName: "兰州佛慈西城药业集团有限责任公司",
          approvalNo: "国药准字J20160073",
          prodBarcode: "6932721511289",
          prodValidTime: " 36 月",
          detailSet: [],
          table: "",
        },
        {
          id: 78,
          img: require("../../assets/images/DUC001007F.png"),
          name: "婴幼儿牙膏 0-2岁",
          cDrugNm: "婴幼儿牙膏 0-2岁",
          businessId: 12,
          factPrice: 42,
          retailPrice: 42,
          specification: "75g",
          packageUnit: "盒",
          manufacture: "北京韩美药品有限公司",
          branchName: "九州通生活旗舰店",
          approvalNo: "",
          prodBarcode: "6932721511302",
          prodValidTime: " 24 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211115/1636960039.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636962724.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636957242.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636955429.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636955601.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636958958.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636958581.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636955508.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636956653.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636960078.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636959052.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636959470.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636957187.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636955314.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636957969.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636964612.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636960176.jpg",
          ],
          table: "",
        },
        {
          id: 79,
          img: require("../../assets/images/ENC002002C.png"),
          name: "Sanita-Denti莎卡 婴幼儿牙刷 1阶段",
          cDrugNm: "Sanita-Denti莎卡 婴幼儿牙刷 1阶段",
          businessId: 12,
          factPrice: 28,
          retailPrice: 28,
          specification: "",
          packageUnit: "刷头1.7cm/刷毛高1.0cm/柄长10.5cm",
          manufacture: "北京韩美药品有限公司",
          branchName: "九州通生活旗舰店",
          approvalNo: "",
          prodBarcode: "6932721511173",
          prodValidTime: "24 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211116/1637049685.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637047128.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637052182.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637048521.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637051146.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637048961.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637046334.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637055232.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637054042.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637052943.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637053887.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637050400.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637046607.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637053207.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637052146.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637049504.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637050341.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637053161.jpg",
          ],
          table: "",
        },
        {
          id: 80,
          img: require("../../assets/images/kcgjmca.jpg"),
          name: "婴幼儿牙刷 2-5岁",
          cDrugNm: "婴幼儿牙刷 2-5岁",
          businessId: 12,
          factPrice: 28,
          retailPrice: 28,
          specification: "",
          packageUnit: "刷头长1.8cm/刷毛高1.1cm /刷柄长13.9cm",
          manufacture: "北京韩美药品有限公司",
          branchName: "九州通生活旗舰店",
          approvalNo: "",
          prodBarcode: "6932721511104",
          prodValidTime: "36 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211116/1637055357.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637056264.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637053717.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637050535.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637054732.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637056212.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637054872.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637051607.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637052368.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637054239.jpg",
          ],
          table: "",
        },
        {
          id: 81,
          img: require("../../assets/images/DAB013068D.png"),
          name: "婴幼儿牙膏 2-5岁（三种口味）",
          cDrugNm: "婴幼儿牙膏 2-5岁",
          businessId: 12,
          factPrice: 42,
          retailPrice: 42,
          specification: "75g",
          packageUnit: "盒",
          manufacture: "北京韩美药品有限公司",
          branchName: "九州通生活旗舰店",
          approvalNo: "国药准字S20030087",
          prodBarcode: "6935714520626",
          prodValidTime: "36 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211126/1637899133.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637900481.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637903968.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637897845.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637899016.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637899399.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637898963.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637901446.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637900531.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637900319.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637906889.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637902937.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637898905.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637904335.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637904605.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637902318.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637898756.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637905476.jpg",
          ],
          table: "",
        },
        {
          id: 82,
          img: require("../../assets/images/HQS016025G.png"),
          name: "儿童牙刷 5岁+",
          cDrugNm: "儿童牙刷 5岁+",
          businessId: 12,
          factPrice: 28,
          retailPrice: 28,
          specification: "1支",
          packageUnit: "套",
          manufacture: "北京韩美药品有限公司",
          branchName: "九州通生活旗舰店",
          approvalNo: "",
          prodBarcode: "6935796802825",
          prodValidTime: "36 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211116/1637055357.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637056264.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637053717.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637050535.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637054732.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637056212.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637054872.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637051607.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637052368.jpg",
            "http://www.ofmom.cn/uploads/image/20211116/1637054239.jpg",
          ],
          table: "",
        },
        {
          id: 83,
          img: require("../../assets/images/EHA002006C.png"),
          name: "婴幼儿牙膏 5岁+",
          cDrugNm: "婴幼儿牙膏 5岁+",
          businessId: 12,
          factPrice: 42,
          retailPrice: 42,
          specification: "75g",
          packageUnit: "盒",
          manufacture: "北京韩美药品有限公司",
          branchName: "九州通生活旗舰店",
          approvalNo: "",
          prodBarcode: "6932721510954",
          prodValidTime: "36 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211126/1637899133.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636976751.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636973244.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637897845.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637899016.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637899399.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637898963.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637901446.jpg",
            "http://www.ofmom.cn/uploads/image/20211115/1636978024.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637900319.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637906889.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637902937.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637898905.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637904335.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637904605.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637902318.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637898756.jpg",
            "http://www.ofmom.cn/uploads/image/20211126/1637905476.jpg",
          ],
          table: "",
        },
        {
          id: 161,
          img: require("../../assets/images/EFA008020C.png"),
          name: "HARMAS PERFECT FIT",
          cDrugNm: "记忆贴合婴儿背带",
          businessId: 33,
          factPrice: 1680,
          retailPrice: 1680,
          specification: "3-36个月",
          packageUnit: "6-22KG",
          manufacture: "北京韩美药品有限公司",
          branchName: "佳能达母婴旗舰店",
          approvalNo: "",
          prodBarcode: "6932721511050",
          prodValidTime: "60 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211117/1637142351.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637136802.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637134931.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637139407.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637144270.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637137881.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637138016.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637141851.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637138599.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637136747.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637135693.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637140708.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637143815.jpg",
          ],
          table: "",
        },
        {
          id: 162,
          img: require("../../assets/images/EWN020001C.png"),
          name: "宝丽亚",
          cDrugNm: "吸入用丙酸倍氯米松混悬液",
          businessId: 33,
          factPrice: 12.9,
          retailPrice: 18,
          specification: "2ml*20支",
          packageUnit: "盒",
          manufacture: "意大利Chiesi Farmaceutici S.p.A.",
          branchName: "佳能达母婴旗舰店", //
          approvalNo: "国药准字H20130214",
          prodBarcode: "bly2020081701",
          prodValidTime: "24 月",
          detailSet: [],
          table: "",
        },
        {
          id: 163,
          img: require("../../assets/images/EWN027001C.png"),
          name: "仿生舒睡婴儿床",
          cDrugNm: "仿生舒睡婴儿床",
          businessId: 33,
          factPrice: 768,
          retailPrice: 768,
          specification: "78cm*50cm*13cm",
          packageUnit: "0-24个月",
          manufacture: "北京韩美药品有限公司",
          branchName: "佳能达母婴旗舰店",
          approvalNo: "",
          prodBarcode: "8806435042439",
          prodValidTime: "3年",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211117/1637134389.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637134999.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637134294.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637137563.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637143139.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637143320.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637137264.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637143126.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637139846.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637136924.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637139724.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637142935.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637141953.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637137555.jpg",
          ],
          table: "",
        },
        {
          id: 164,
          img: require("../../assets/images/BUC007007F.png"),
          name: "多功能便携婴儿床",
          cDrugNm: "多功能便携婴儿床",
          businessId: 33,
          factPrice: 688,
          retailPrice: 688,
          specification: "78cm*50cm*13cm",
          packageUnit: "0-24个月",
          manufacture: "北京韩美药品有限公司",
          branchName: "佳能达母婴旗舰店",
          approvalNo: "",
          prodBarcode: "6932721511104",
          prodValidTime: "36 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211117/1637137833.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637141901.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637134463.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637136602.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637143816.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637138166.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637136980.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637141248.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637141643.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637143264.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637138158.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637139930.jpg",
          ],
          table: "",
        },
        {
          id: 154,
          img: require("../../assets/images/ENA005155C.png"),
          name: "哄睡婴儿摇椅",
          cDrugNm: "哄睡婴儿摇椅",
          businessId: 33,
          factPrice: 868,
          retailPrice: 868,
          specification: "78cm*50cm*13cm",
          packageUnit: "0-24个月",
          manufacture: "北京韩美药品有限公司",
          branchName: "佳能达母婴旗舰店",
          approvalNo: "",
          prodBarcode: "6932721510978",
          prodValidTime: "36 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211117/1637143977.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637143469.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637138122.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637141708.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637140455.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637136018.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637136421.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637142040.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637140771.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637140734.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637143678.jpg",
          ],
          table: "",
        },
        {
          id: 149,
          img: require("../../assets/images/kfysby.png"),
          name: "HARMAS LIGHT FIT",
          cDrugNm: "纤巧轻舒婴儿背带",
          businessId: 33,
          factPrice: 1680,
          retailPrice: 1680,
          specification: "3-36个月",
          packageUnit: "6-22KG",
          manufacture: "北京韩美药品有限公司",
          branchName: "佳能达母婴旗舰店",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211117/1637142351.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637136802.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637134931.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637139407.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637144270.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637137881.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637138016.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637141851.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637138599.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637136747.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637135693.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637140708.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637143815.jpg",
          ],
        },
        {
          id: 200,
          img: require("../../assets/images/tdx123.jpg"),
          name: "HARMAS",
          cDrugNm: "多功能妈咪包",
          businessId: 33,
          factPrice: 1299,
          retailPrice: 1299,
          specification: "",
          packageUnit: "",
          manufacture: "北京韩美药品有限公司",
          branchName: "佳能达母婴旗舰店",
          detailSet: [],
        },
        {
          id: 159,
          img: require("../../assets/images/DOE001070C.png"),
          name: "Ofmom Yoom Signature",
          cDrugNm: "婴儿手推车玫瑰金车架闪金色",
          businessId: 52,
          factPrice: 10999,
          retailPrice: 10999,
          specification: "0-22KG",
          packageUnit: "0-36个月",
          manufacture: "北京韩美药品有限公司",
          branchName: "明朗生活旗舰店",
          approvalNo: "",
          prodBarcode: "6932721511043",
          prodValidTime: "240 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211117/1637123892.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637119261.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637123676.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637121669.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637123788.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637121187.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637120646.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637120477.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637119259.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637122870.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637117097.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637117632.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637120486.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637118470.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637122901.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637125742.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637121511.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637118345.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637122790.jpg",
          ],
          table: "",
        },
        {
          id: 153,
          img: require("../../assets/images/HQS016011G.png"),
          name: "Ofmom Yoom Signature",
          cDrugNm: "婴儿手推车黑色车架珍珠白蜂巢色",
          businessId: 52,
          factPrice: 8999,
          retailPrice: 8999,
          specification: "0-22KG",
          packageUnit: "0-36个月",
          manufacture: "北京韩美药品有限公司",
          branchName: "明朗生活旗舰店",
          approvalNo: "",
          prodBarcode: "6935796802825",
          prodValidTime: " 240 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211117/1637123892.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637119261.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637123676.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637121669.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637123788.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637121187.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637120646.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637120477.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637119259.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637122870.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637117097.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637117632.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637120486.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637118470.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637122901.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637125742.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637121511.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637118345.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637122790.jpg",
          ],
          table: "",
        },
        {
          id: 155,
          img: require("../../assets/images/HQB002010C.png"),
          name: "Ofmom Yoom Signature",
          cDrugNm: "婴儿手推车黑色车架碳黑蜂巢色",
          businessId: 52,
          factPrice: 8999,
          retailPrice: 8999,
          specification: "0-22KG",
          packageUnit: "0-36个月",
          manufacture: "北京韩美药品有限公司",
          branchName: "明朗生活旗舰店",
          approvalNo: "",
          prodBarcode: "6935796802825",
          prodValidTime: " 240 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211117/1637123892.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637119261.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637123676.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637121669.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637123788.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637121187.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637120646.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637120477.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637119259.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637122870.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637117097.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637117632.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637120486.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637118470.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637122901.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637125742.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637121511.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637118345.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637122790.jpg",
          ],
          table: "",
        },
        {
          id: 156,
          img: require("../../assets/images/HGF005001C.png"),
          name: "Ofmom Yoom Signature",
          cDrugNm: "婴儿手推车黑色车架巴黎薄荷绿色",
          businessId: 52,
          factPrice: 9399,
          retailPrice: 9399,
          specification: "0-22KG",
          packageUnit: "0-36个月",
          manufacture: "北京韩美药品有限公司",
          branchName: "明朗生活旗舰店",
          approvalNo: "",
          prodBarcode: "6932721511388",
          prodValidTime: " 24 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211117/1637123892.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637119261.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637123676.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637121669.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637123788.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637121187.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637120646.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637120477.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637119259.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637122870.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637117097.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637117632.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637120486.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637118470.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637122901.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637125742.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637121511.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637118345.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637122790.jpg",
          ],
          table: "",
        },
        {
          id: 157,
          img: require("../../assets/images/ILELEYSJ20P.png"),
          name: "Ofmom Yoom Easy Folding",
          cDrugNm: "婴儿手推车黑色车架巴黎粉色",
          businessId: 52,
          factPrice: 2999,
          retailPrice: 2999,
          specification: "0-22KG",
          packageUnit: "0-36个月",
          manufacture: "北京韩美药品有限公司",
          branchName: "明朗生活旗舰店",
          approvalNo: "",
          prodBarcode: "6932721511388",
          prodValidTime: " 240 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211117/1637129984.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637129932.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637124234.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637126155.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637128205.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637126816.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637128113.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637124507.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637126131.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637124775.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637122628.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637124181.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637122193.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637123792.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637130051.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637123935.jpg",
          ],
        },
        {
          id: 158,
          img: require("../../assets/images/DAB007021D.png"),
          name: "Ofmom Yoom Easy Folding",
          cDrugNm: "婴儿手推车黑色车架巴黎白色",
          businessId: 52,
          factPrice: 2999,
          retailPrice: 2999,
          specification: "0-22KG",
          packageUnit: "0-36个月",
          manufacture: "北京韩美药品有限公司",
          branchName: "明朗生活旗舰店",
          approvalNo: "",
          prodBarcode: "6932721511388",
          prodValidTime: " 240 月",
          detailSet: [
            "http://www.ofmom.cn/uploads/image/20211117/1637129984.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637129932.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637124234.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637126155.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637128205.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637126816.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637128113.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637124507.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637126131.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637124775.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637122628.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637124181.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637122193.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637123792.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637130051.jpg",
            "http://www.ofmom.cn/uploads/image/20211117/1637123935.jpg",
          ],
          table: "",
        },
      ],
      tabsActiveName: "productDetail",
      drugCount: 1,
      configs: {
        width: 300,
        height: 300,
        maskWidth: 100,
        maskHeight: 100,
        maskColor: "#000",
        maskOpacity: 0.2,
      },
    };
  },
  computed: {
    drugInfo() {
      for (let i = 0; i < this.drugOpts.length; i++) {
        const item = this.drugOpts[i];
        if (item.id == this.id) {
          item.count = 1;
          return item;
        }
      }
    },
    manufactName() {
      let name = this.drugInfo.branchName.replace("旗舰店", "有限公司");
      console.log(name);
      return name;
    },
    ...mapGetters(["shopCartList", "cartTotalCount", "userName"]),
  },
  watch: {
    id: {
      deep: true,
      handler(nVal, oVal) {
        if (nVal !== oVal) {
          this.$router.go(0);
        }
      },
      // immediate: true,
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      addGoodsCart: "carts/addGoodsCart",
      calculateCartCount: "carts/calculateCartCount",
      addUnitGoodOrder: "carts/addUnitGoodOrder",
    }),
    orderSubmit() {
      if (this.userName) {
        let temp = this.handlerShopCartOrder();
        this.addUnitGoodOrder(temp);
        this.addGoodsCart(temp);
        this.calculateCartTotal();
        this.$router.push({
          path: "/ordersubmit",
          query: {
            type: "goods",
          },
        });
      } else {
        this.$router.push({
          path: "/register",
          query: {
            tab: "login",
          },
        });
      }
    },
    joinShopCart() {
      let temp = this.handlerShopCartOrder();
      this.addGoodsCart(temp);
      this.calculateCartTotal();
      ElMessage({
        message: `加购${temp.cDrugNm}成功`,
        type: "success",
      });
    },
    handlerShopCartOrder() {
      let temp = JSON.parse(JSON.stringify(this.drugInfo));
      temp.count = this.drugCount;
      temp.type = "detail";
      temp.subtotal = (temp.count * temp.retailPrice).toFixed(2);
      return temp;
    },
    calculateCartTotal() {
      let totalCount = 0;
      if (this.shopCartList) {
        for (let i = 0; i < this.shopCartList.length; i++) {
          const { count } = this.shopCartList[i];
          totalCount += count;
        }
        this.calculateCartCount(totalCount);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.recommond-tab-title {
  font-size: 16px;
  line-height: 40px;
  text-align: left;
  .recommond-tab-div {
    border-bottom: 2px solid #022a75;
    span {
      display: inline-block;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-width: 5px;
        border-style: dashed dashed solid dashed;
        border-color: transparent transparent #022a75;
      }
    }
  }
}
img {
  width: 100%;
}
.product-top-row {
  border: 1px solid #dedede;
  border-top: 2px solid #022a75;
  margin: 15px auto 20px;
  padding: 20px;
  background: #fff;
  flex-flow: row nowrap;
  .product-top-left {
    width: 520px;
    flex: 1;
    .product-top-img {
      width: 100%;
    }
  }
  .product-top-right {
    flex: auto;
  }
}
.product-info {
  padding-left: 15px;
  text-align: left;
  .product-name {
    padding: 5px 0;
    color: #222;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px dashed #ddd;
  }
  .drug-button {
    :deep(.el-button) {
      width: 150px;
      height: 40px;
      font-size: 16px;
      color: #fff;
      letter-spacing: 2px;
      .el-icon {
        font-size: 20px;
        margin-right: 4px;
      }
    }
    .purchase-button {
      background-color: #f98800;
    }
    .join-button {
      background-color: #003a8e;
    }
  }

  .drug-info-dlist {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  dl {
    padding: 13px 0;
    border-bottom: 1px dashed #e5e5e5;
    font-size: 12px;
    dt,
    dd {
      display: inline-block;
      vertical-align: middle;
      height: 24px;
      line-height: 24px;
      color: #999;
    }
    dt {
      width: 70px;
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
    }
    dd {
      width: 205px;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .drug-retail-price {
    background: #f4f4f4;
    padding: 5px 0;

    .font-weight {
      font-size: 30px;
      font-weight: bold;
      position: relative;
      margin-left: 2px;
    }
    dd {
      color: #e50000;
      font-size: 18px;
    }
  }
  .drug-freight,
  .drug-pay,
  .drug-retail-price {
    dl {
      border-bottom: none;
    }
  }
  .drug-freight {
    dd {
      width: auto;
      padding-left: 10px;
    }
  }
  .drug-pay {
    dd {
      width: auto;
      padding-left: 10px;
    }
    dt,
    dd {
      height: auto;
    }
  }
}
.product-tabs-theme {
  text-align: left;
  color: #212c91;
  font-size: 15px;
  margin-bottom: 6px;
}
.product-tabs-ulist {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  margin: 10px auto;
  font-size: 12px;
  color: #555;
  li {
    line-height: 26px;
  }
}
.pre-druginfo-table {
  margin-bottom: 20px;
  :deep(table) {
    font-size: 14px;
    text-align: left;
    td {
      padding: 6px 10px;
      line-height: 2;
      color: #656b78;
      white-space: pre-wrap;
      font-family: emoji;
    }
  }
}
</style>
