<template>
  <div>
    <h4 class="center-header"><span class="center-theme">我的订单</span></h4>
    <el-table :data="orderOpts" style="width: 100%" empty-text="暂无数据">
      <el-table-column label="订单编号" prop="orderNm">
        <template #default="scope">
          {{ scope.row.orderNm }}
        </template>
      </el-table-column>
      <el-table-column label="下单日期" prop="createTime">
        <template #default="scope">
          {{ scope.row.createTime }}
        </template>
      </el-table-column>
      <el-table-column label="收货人" prop="consignee">
        <template #default="scope">
          {{ scope.row.consignee }}
        </template>
      </el-table-column>
      <el-table-column label="支付方式" prop="deliveMethod">
        <template #default="scope">
          {{ scope.row.deliveMethod }}
        </template>
      </el-table-column>
      <el-table-column label="总金额" prop="totalPrice">
        <template #default="scope">
          {{ scope.row.totalPrice }}
        </template>
      </el-table-column>
      <el-table-column label="订单状态" prop="status">
        <template #default="scope">
          <span class="td-span-font">{{ scope.row.status }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" prop="option">
        <template #default="scope">
          <template v-if="scope.row.status == '等待付款'">
            <el-button type="text" @click="cancleOrder(scope.row)">取消订单</el-button>
            <el-button type="text" @click="paymentOrder">立即付款</el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      v-model="deliveVisible"
      title="选择支付方式"
      width="400px"
      center
      :close-on-click-modal="false"
      @close="modalClose"
    >
      <div class="width-360">
        <el-radio v-model="deliveMethod" label="预存款">
          <div class="display-flex">
            <img :src="orderImgSrc" alt="" /> <span>预存款</span>
          </div>
        </el-radio>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="submitDelive">立即支付</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
/****************
 *@author: niubj
 *@date: 2022-01-13 15:06:30
 *@version: V1.0.0
 *@description: 订单我的
 *************************************************************************/
import { mapActions, mapGetters } from "vuex";
export default {
  name: "RmkOrder",
  components: {},
  props: {},
  data() {
    return {
      orderImgSrc: require("../../../assets/pay_deposit.gif"),
      deliveMethod: "预存款",
      deliveVisible: false,
    };
  },
  computed: {
    ...mapGetters(["orderOpts"]),
  },
  watch: {},
  methods: {
    ...mapActions({
      setEditOrderOpts: "user/setEditOrderOpts",
    }),
    // 立即支付选择支付方式
    paymentOrder() {
      this.deliveVisible = true;
    },
    modalClose() {
      this.deliveVisible = false;
    },
    submitDelive() {
      ElMessage.error("余额不足请充值");
      this.modalClose();
    },
    cancleOrder($order) {
      // 修改订单状态
      const opts = { ...$order };
      opts.status = "已取消";
      this.setEditOrderOpts(opts);
    },
  },
};
</script>
<style lang="less" scoped>
.td-span-font {
  color: #f29518;
  font-weight: 550;
}
.width-360 {
  display: flex;
  align-items: center;
  width: 360px;
  :deep(.el-radio) {
    margin-right: 4px;
  }
  .display-flex {
    display: inline-flex;
    align-items: center;
    font-weight: 550;
    font-size: 15px;
    span {
      position: relative;
      top: 3px;
    }
  }
}
.dialog-footer {
  :deep(.el-button) {
    width: 180px;
    height: 40px;
    font-size: 16px;
  }
}
</style>
