<template>
  <el-tabs v-model="tabsActiveName">
    <el-tab-pane label="未评价" name="refundsMoney">
      <el-table :data="dataSource" style="width: 100%" empty-text="暂无数据">
        <el-table-column label="订单编号" prop="numId">
          <template #default="scope">
            {{ scope.row.numId }}
          </template>
        </el-table-column>
        <el-table-column label="商品名称" prop="status">
          <template #default="scope">
            {{ scope.row.status }}
          </template>
        </el-table-column>
        <el-table-column label="购买时间" prop="createTime">
          <template #default="scope">
            {{ scope.row.createTime }}
          </template>
        </el-table-column>
        <el-table-column label="评价" prop="option">
          <template #default="scope">
            {{ scope.row.option }}
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="已评价" name="exchangeGoods">
      <el-table :data="dataSource" style="width: 100%" empty-text="暂无数据">
        <el-table-column label="商品名称" prop="numId">
          <template #default="scope">
            {{ scope.row.numId }}
          </template>
        </el-table-column>
        <el-table-column label="订单编号" prop="createTime">
          <template #default="scope">
            {{ scope.row.createTime }}
          </template>
        </el-table-column>
        <el-table-column label="评论时间" prop="status">
          <template #default="scope">
            {{ scope.row.status }}
          </template>
        </el-table-column>
        <el-table-column label="评分" prop="option">
          <template #default="scope">
            {{ scope.row.option }}
          </template>
        </el-table-column>
        <el-table-column label="评价" prop="option">
          <template #default="scope">
            {{ scope.row.option }}
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-13 15:59:53
 *@version: V1.0.0
 *@description: 商品评价
 *************************************************************************/
export default {
  name: "RmkRefunds",
  components: {},
  props: {},
  data() {
    return {
      tabsActiveName: "refundsMoney",
    };
  },

  computed: {},
  watch: {},

  methods: {},
};
</script>
<style lang="less" scoped></style>
