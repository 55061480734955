<template>
  <h1 class="header-logo"><img :src="logoSrc" alt="北京英芙麦迪科技有限公司"   @click="jumpToPage" /></h1>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-12 08:35:48
 *@version: V1.0.0
 *@description: 顶部栏logo与搜索框
 *************************************************************************/
export default {
  name: "PageLogo",
  components: {},
  props: {},
  data() {
    return {
      logoSrc: require("../assets/logo.png"),
    };
  },

  computed: {},
  watch: {},

  methods: {
    jumpToPage() {
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.header-logo {
  text-align: left;
  img {
    height: 50px;
    width: auto;
    cursor: pointer;
  }
}
</style>
