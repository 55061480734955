/****************
 *@author: niubj
 *@date: 2022-01-24 14:01:32
 *@version: V1.0.0
 *@description: 用户信息、用户订单数据
 *************************************************************************/
const userAddress = localStorage.getItem("userAddress");
const orderOpts = localStorage.getItem("rmkOrderOpts");
const rmkUnitOrder = localStorage.getItem("rmkUnitOrder");
const state = {
  userName: localStorage.getItem("rmkOrderUserName") || "",
  userAddress: userAddress ? JSON.parse(userAddress) : [],
  orderOpts: orderOpts ? JSON.parse(orderOpts) : [],
  rmkUnitOrder: rmkUnitOrder? JSON.parse(rmkUnitOrder): {},
};
const mutations = {
  SET_USERNAME(state, data) {
    state.userName = data;
    localStorage.setItem("rmkOrderUserName", data);
  },
  /**
   * 添加地址
   * @param {*} state
   * @param {*} data
   */
  SET_USERADDRESS(state, data) {
    state.userAddress.push(Object.assign({}, data));
    localStorage.setItem("userAddress", JSON.stringify(state.userAddress));
  },
  SET_EDITADDRESS(state, data) {
    for (let i = 0; i < state.userAddress.length; i++) {
      let item = state.userAddress[i];
      if (item.id == data.id) {
        state.userAddress[i] = JSON.parse(JSON.stringify(data));
      }
    }
    localStorage.setItem("userAddress", JSON.stringify(state.userAddress));
  },
  /**
   * 新增订单
   * @param {*} state
   * @param {*} data
   */
  SET_ORDER(state, data) {
    state.orderOpts.push(Object.assign({}, data));
    state.rmkUnitOrder = { ...data };
    sessionStorage.setItem("rmkUnitOrder", JSON.stringify(data));
    localStorage.setItem("rmkOrderOpts", JSON.stringify(state.orderOpts));
  },
  SET_EDITORDER(state, data) {
    for (let i = 0; i < state.orderOpts.length; i++) {
      let item = state.orderOpts[i];
      if (item.orderNm == data.orderNm) {
        state.orderOpts[i] = JSON.parse(JSON.stringify(data));
      }
    }
    localStorage.setItem("rmkOrderOpts", JSON.stringify(state.orderOpts));
  },
};
const actions = {
  login({ commit }, useInfo) {
    return new Promise((resolve) => {
      const { username } = useInfo;
      commit("SET_USERNAME", username);
      resolve();
    });
  },
  /**
   * 添加地址
   * @param {*} param0
   * @param {*} data
   */
  setUserAddress({ commit }, data) {
    commit("SET_USERADDRESS", data);
  },
  /**
   * 编辑地址
   * @param {*} param0
   * @param {*} data
   */
  setEditUserAddress({ commit }, data) {
    commit("SET_EDITADDRESS", data);
  },
  //下订单
  setOrderOpts({ commit }, data) {
    commit("SET_ORDER", data);
  },
  // 编辑订单
  setEditOrderOpts({ commit }, data){
    commit("SET_EDITORDER", data);
  }
};
export default {
  state,
  mutations,
  actions,
};
