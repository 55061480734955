<template>
  <h4 class="center-header">
    <span class="center-theme">商品咨询</span>
  </h4>
  <el-table :data="dataSource" style="width: 100%" empty-text="暂无数据">
    <el-table-column label="商品名称" prop="drugNm">
      <template #default="scope">
        {{ scope.row.drugNm }}
      </template>
    </el-table-column>
    <el-table-column label="内容" prop="content">
      <template #default="scope">
        {{ scope.row.content }}
      </template>
    </el-table-column>
    <el-table-column label="咨询时间" prop="createTime">
      <template #default="scope">
        {{ scope.row.createTime }}
      </template>
    </el-table-column>
    <el-table-column label="回复" prop="reply">
      <template #default="scope">
        {{ scope.row.reply }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-13 15:29:52
 *@version: V1.0.0
 *@description: 商品咨询
 *************************************************************************/
export default {
  name: "RmkConsult",
  components: {},
  props: {
    info: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      dataSource: this.info,
    };
  },
  computed: {},
  watch: {
    info: {
      deep: true,
      handler(nval) {
        this.$nextTick(() => {
          this.dataSource = [...nval];
        });
      },
    },
  },
  methods: {},
};
</script>
<style lang="less" scoped></style>
