<template>
  <div class="container">
    <div class="page-breadcrumb">
      您当前的位置： <span @click="jumpToHome">首页</span> » 成功提交订单
    </div>
    <div class="order-page-content">
      <h5>成功提交订单</h5>
      <ul class="order-info-list">
        <li class="order-info-item">
          <h6>订单已提交<span class="fr" @click="jumpToOrder">查看订单</span></h6>
          <table class="item-table" border="0" cellspacing="0">
            <tr>
              <th>订单编号：</th>
              <td>{{ rmkUnitOrder.orderNm }}</td>
            </tr>
            <tr>
              <th>订单金额：</th>
              <td>
                ￥<b>{{ rmkUnitOrder.totalPrice }}</b>
              </td>
            </tr>
            <tr>
              <th>支付方式：</th>
              <td>{{ rmkUnitOrder.deliveMethod }}</td>
            </tr>
            <tr>
              <th>收货时间：</th>
              <td>{{ rmkUnitOrder.deliveTime }}</td>
            </tr>
            <tr>
              <th>配送方式：</th>
              <td>{{ rmkUnitOrder.deliveType }}</td>
            </tr>
            <tr>
              <th>备货时间：</th>
              <td>2 天</td>
            </tr>
          </table>
        </li>
      </ul>
      <div class="order-button">
        <el-button @click="submitOrder">立即支付</el-button>
      </div>
    </div>
  </div>
  <el-dialog
      v-model="deliveVisible"
      title="选择支付方式"
      width="400px"
      center
      :close-on-click-modal="false"
      @close="modalClose"
    >
      <div class="width-360">
        <el-radio v-model="deliveMethod" label="预存款">
          <div class="display-flex">
            <img :src="orderImgSrc" alt="" /> <span>预存款</span>
          </div>
        </el-radio>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="submitDelive">立即支付</el-button>
        </span>
      </template>
    </el-dialog>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-27 10:30:28
 *@version: V1.0.0
 *@description: 订单提交成功返回页面
 *************************************************************************/
import { mapGetters } from "vuex";
import { ElMessage } from "element-plus/lib/components";
export default {
  name: "orderSuccess",
  components: {},
  props: {},
  data() {
    return {
      orderImgSrc: require("../../assets/pay_deposit.gif"),
      deliveMethod: "预存款",
      deliveVisible: false,
    };
  },
  computed: {
    ...mapGetters(["orderOpts", "rmkUnitOrder", "userAddress"]),
  },
  watch: {},
  mounted() {},
  methods: {
    jumpToHome() {
      this.$router.push({
        path: "/",
      });
    },
    modalClose() {
      this.deliveVisible = false;
    },
    submitDelive() {
      ElMessage.error("余额不足请充值");
      this.modalClose();
    },
    submitOrder() {
      this.deliveVisible = true;
    },
    jumpToOrder() {
      this.$router.push({
        path: "/ucenter",
        query: {
          id: "order",
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.order-page-content {
  margin: 0 auto 15px;
  border: 1px solid #ddd;
  background: #fff;
  text-align: left;
  h5 {
    background: #fff;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #ddd;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    text-indent: 20px;
    font-weight: bold;
  }
  .order-button {
    margin: 20px 20px 20px 0;
    text-align: right;
    :deep(.el-button) {
      background-color: #ff8225;
      min-width: 140px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }
  }
}
.order-info-item {
  padding: 20px;
  h6 {
    border-top: 2px solid #e71f19;
    padding: 10px;
    color: #ff8225;
    font-size: 15px;
    font-weight: bold;
    span {
      position: relative;
      top: 8px;
      font-weight: normal;
      color: #555;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .item-dlist {
    padding: 10px 20px;
    border: 1px solid rgba(245, 197, 58, 0.3);
    dt {
      border-bottom: 1px solid rgba(245, 197, 58, 0.3);
      font-size: 14px;
      padding: 8px 0;
      margin-bottom: 5px;
    }
    dd {
      line-height: 2;
      font-size: 12px;
      :deep(.el-radio) {
        margin-right: 10px;
      }
    }
    .order-address-editbtn {
      padding: 4px 10px;
    }
  }
  .item-table {
    width: 100%;
    text-align: left;
    border: 1px solid #ddd;
    border-bottom: none;
    font-size: 13px;
    th {
      width: 180px;
      padding: 8px 8px 8px 30px;
      line-height: 1.6;
      border-width: 0 1px 1px 0;
      border-color: #ddd;
      border-style: solid;
      :deep(.el-radio__label) {
        font-weight: 550;
      }
      &.width-360 {
        width: 360px;
        :deep(.el-radio) {
          vertical-align: top;
        }
        .display-flex {
          display: inline-flex;
          align-items: center;
        }
      }
    }
    td {
      padding-left: 10px;
      border-bottom: 1px solid #ddd;
      :deep(.el-input) {
        width: 360px;
      }
    }
  }
  .item-cart-count {
    padding: 20px;
    background-color: rgba(245, 197, 58, 0.06);
    .cart--count-detail {
      font-size: 14px;
      .font-weight {
        font-weight: 550;
        color: #e71f19;
      }
    }
    p {
      text-align: right;
      font-size: 12px;
      .font-price {
        display: inline-block;
        font-size: 16px;
        color: #e71f19;
        vertical-align: middle;
      }
    }
  }
}
.width-360 {
  display: flex;
  align-items: center;
  width: 360px;
  :deep(.el-radio) {
    margin-right: 4px;
  }
  .display-flex {
    display: inline-flex;
    align-items: center;
    font-weight: 550;
    font-size: 15px;
    span {
      position: relative;
      top: 3px;
    }
  }
}
.dialog-footer {
  :deep(.el-button) {
    width: 180px;
    height: 40px;
    font-size: 16px;
  }
}
</style>
