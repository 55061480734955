<template>
  <section class="page-section">
    <div class="container">
      <article class="callback-article error-article" v-if="type == 'search'">
        <i class="icon icon-error"></i>
        <div class="callback-detail">
          <h6>请输入正确的查询关键词</h6>
          <dl>
            <dt>您可以：</dt>
            <dd>1.检查刚才的输入</dd>
            <dd>2.到帮助中心寻求帮助</dd>
            <dd>
              3.去其他地方逛逛： <span @click="goBack">返回上一级操作</span> |
              <span @click="goHome">网站首页</span> |
              <span @click="jumpToUcenter">我的订单</span> |
              <span @click="goShopCart">我的购物车</span>
            </dd>
          </dl>
        </div>
      </article>
      <article class="callback-article success-article" v-else>
        <i class="icon icon-success"></i>
        <div class="callback-detail">
          <h6>申请成功！请耐心等待管理员的审核</h6>
          <dl>
            <dt>您可以：</dt>
            <dd>
              继续操作>>
              <span @click="jumpToUcenter">个人中心</span> >>
              <span @click="goHome">网站首页</span>
            </dd>
          </dl>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-13 10:48:33
 *@version: V1.0.0
 *@description: 功能描述
 *************************************************************************/
import { mapGetters } from "vuex";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      type: this.$route.query.type,
    };
  },
  computed: {
    ...mapGetters(["userName"]),
  },
  watch: {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goHome() {
      this.$router.push({
        path: "/",
      });
    },
    goShopCart() {
      this.$router.push({
        path: "/shopCart",
      });
    },
    jumpToUcenter() {
      if (this.userName) {
        this.$router.push({
          path: "/ucenter",
          query: {
            id: "index",
          },
        });
      } else {
        this.$router.push({
          path: "/register",
          query: {
            tab: "login",
          },
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.page-section {
  background-color: #fff;
}
.callback-article {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto 50px;
  padding: 50px 40px;
  width: 600px;
  font-size: 12px;
  &.error-article {
    box-shadow: 0 0 5px #e71f19;
  }
  &.success-article {
    box-shadow: 0 0 5px #379339;
    justify-content: space-evenly;
  }
  .icon {
    display: block;
    width: 140px;
    height: 140px;
    &.icon-error {
      background: url("../../assets/cry.gif") no-repeat center;
    }
    &.icon-success {
      background: url("../../assets/right.gif") no-repeat center;
    }
  }
  .callback-detail {
    line-height: 2;
    text-align: left;
    span {
      cursor: pointer;
    }
  }
}
</style>
