<template>
  <div class="business-login-page">
    <div class="container">
      <div class="business-login-wrapper">
        <div class="business-login-box">
          <h4>商家管理中心</h4>
          <el-form
            class="business-login-form"
            :model="loginForm"
            :rules="loginRules"
            ref="loginFormRef"
          >
            <el-form-item prop="username" required>
              <el-input v-model="loginForm.username" placeholder="用户名"></el-input>
            </el-form-item>
            <el-form-item prop="password" required>
              <el-input
                v-model="loginForm.password"
                placeholder="密码"
                autocomplete="off"
                type="password"
                @keyup.enter="onSubmit"
              ></el-input>
            </el-form-item>
            <el-form-item class="login-form-btn">
              <el-button class="login-btn" @click="onSubmit">登录</el-button>
            </el-form-item>
            <el-form-item class="login-form-btn">
              <el-button class="join-btn" @click="jumpToPage">商家入驻</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-13 08:45:08
 *@version: V1.0.0
 *@description: 功能描述
 *************************************************************************/
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [
          {
            required: true,
            message: "用户名不能为空！",
          },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            min: 6,
          },
        ],
      },
    };
  },

  computed: {},
  watch: {},

  methods: {
    onSubmit() {
      this.$refs.loginFormRef.validate().then(() => {
        this.$router.push({
          path: "/",
        });
        this.$refs.loginFormRef.resetFields();
      });
    },
    jumpToPage() {
      this.$router.push({
        path: "/applyJoin",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.business-login-page {
  width: 100%;
  height: 580px;
  background: url("../../assets/info_bg.jpg") no-repeat center/cover;
  background-size: 100%;
}
.login-form-btn {
  :deep(.el-button) {
    width: 100%;
    color: #fff;
    letter-spacing: 2px;
    &.login-btn {
      background-color: #003a8e;
    }
    &.join-btn {
      background-color: #f98800;
    }
  }
}
.business-login-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-top: 120px;
}
.business-login-box {
  position: relative;
  width: 300px;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 40px 60px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  z-index: 1;
  h4 {
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: normal;
  }
}
</style>
