<template>
  <!-- 顶部 -->
  <div class="header-top">
    <el-row justify="space-between" class="top-row container">
      <el-col :span="12">
        <span>欢迎您来到SCSS智慧系统！</span>
        <ul v-if="userName.length == 0" class="header-flex">
          <li class="item-link" @click="jumpToPage('login')">请登录</li>
          <li class="item-divide">|</li>
          <li class="item-link" @click="jumpToPage('register')">免费注册</li>
        </ul>
      </el-col>
      <el-col :span="12" class="right-float">
        <ul class="header-flex">
          <li class="item-link" @click="jumpToMyBusiness">我的商城</li>
          <li class="item-divide">|</li>
          <li class="item-link" @click="businessManagement">商家中心</li>
        </ul>
      </el-col>
    </el-row>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-11 16:15:55
 *@version: V1.0.0
 *@description: 头部菜单栏
 *************************************************************************/
import { mapGetters } from "vuex";
export default {
  name: "PageHeader",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["userName"]),
  },
  watch: {},

  methods: {
    jumpToPage($query) {
      this.$router.push({
        path: "/register",
        query: {
          tab: $query,
        },
      });
    },
    businessManagement() {
      this.$router.push({
        name: "businessManagement",
      });
    },
    jumpToMyBusiness() {
      if (this.userName.length == 0) {
        this.jumpToPage("login");
      } else {
        this.$router.push({
          path: "/ucenter",
          query: {
            id: "index",
          },
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.header-top {
  padding: 0 1.5rem;
  background: #f5f4f4;
  color: #555;
  border-bottom: 1px solid #eaeaea;
}
.top-row {
  text-align: left;
  font-size: 12px;
  line-height: 31px;
}
li {
  list-style: none;
}
ul {
  padding: 0;
  margin: 0;
}
.header-flex {
  display: inline-flex;
  align-items: center;
  .item-link {
    cursor: pointer;
  }
  .item-divide {
    padding: 0 6px;
  }
}
.right-float {
  .header-flex {
    float: right;
  }
}
</style>
