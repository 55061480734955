<template>
  <div class="page--main-menu">
    <div class="container">
      <el-menu
        mode="horizontal"
        :default-active="activeIndex"
        background-color="transparent"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <template v-for="(item, i) in routeList" :key="i + 1">
          <el-menu-item v-model:key="item.link" :index="item.link">
            <router-link :to="item.link">{{ item.title }}</router-link>
          </el-menu-item>
        </template>
      </el-menu>
    </div>
  </div>
  <section class="page-section-join">
    <div class="join-img">
      <div class="container">
        <div class="join-img-content">
          <h4>开放多模式·品牌新机遇</h4>
          <p>多模式/多选择/新机遇/心未来</p>
        </div>
      </div>
    </div>
  </section>
  <section class="page-section">
    <div class="container">
      <ul class="business-step-list">
        <li v-for="(item, i) in stepOpts" :key="i">
          <h4>{{ item.title }}</h4>
          <p>{{ item.desc }}</p>
          <el-button @click="jumpToPage(item.link)">{{ item.btnCtn }}</el-button>
        </li>
      </ul>
    </div>
  </section>
  <section class="page-section">
    <div class="container">
      <h3 class="page-title"><span>入驻流程</span></h3>
      <el-row class="business-settled-row">
        <el-col v-for="(item, i) in settledOpts" :key="i" :span="6">
          <div class="settled-content">
            <div class="settled-symbol"><i class="icon"></i></div>
            <dl class="settled-dlist height260">
              <dt class="settled-title">{{ item.title }}</dt>
              <dt class="settled-period">
                <el-button>{{ item.period }}</el-button>
              </dt>
              <dd v-for="(step, i) in item.stepOpt" :key="i">{{ step }}</dd>
            </dl>
            <div v-if="i != settledOpts.length - 1" class="height260 settled-icon">
              <el-icon><arrow-right /></el-icon>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </section>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2022-01-12 16:40:39
 *@version: V1.0.0
 *@description: 商家入驻
 *************************************************************************/
import { ArrowRight } from "@element-plus/icons-vue";
export default {
  name: "",
  components: { ArrowRight },
  props: {},
  data() {
    return {
      routeList: [
        {
          link: "/",
          title: "首页",
        },
        {
          link: "/business",
          title: "商家入驻申请",
        },
        {
          link: "/business/businessManagement",
          title: "商家管理中心",
        },
      ],
      settledOpts: [
        {
          title: "提交入驻资料",
          period: "约2小时",
          stepOpt: [
            "填写企业信息",
            "选择店铺类型/类目",
            "填写品牌信息/资质信息",
            "店铺名称",
            "确认合同/提交审核",
          ],
        },
        {
          title: "商家等待审核",
          period: "3-6工作日",
          stepOpt: ["资质审核", "品牌审核"],
        },
        {
          title: "开通缴费",
          period: "约10分钟",
          stepOpt: ["实名认证/一致性校验", "签署代扣协议", "缴纳费用"],
        },
        {
          title: "店铺上线",
          period: "约20分钟",
          stepOpt: ["发布商品"],
        },
      ],
      stepOpts: [
        {
          link: "/applyJoin",
          title: "入驻申请",
          desc: "未填写入驻申请资料时进行入驻资料填写",
          btnCtn: "我要入驻",
        },
        {
          link: "/business/businessManagement",
          title: "入驻进度",
          desc: "店铺还未开通时了解店铺开通的最新状况",
          btnCtn: "查看入驻进度",
        },
      ],
      activeIndex: "/business",
    };
  },

  computed: {},
  watch: {},
  methods: {
    jumpToPage(link) {
      this.$router.push({
        path: link,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.page-section {
  background-color: #fff;
}
.page--main-menu{
  background-color: #022a75;
}
:deep(.el-menu--horizontal){
  border-bottom: none;
}
.join-img {
  height: 334px;
  width: 100%;
  background: url("../../assets/bottom_bg.jpg") no-repeat center/cover;
  background-size: 100% 100%;
  .container {
    position: relative;
    height: 100%;
  }
  .join-img-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(50%);
    height: 200px;
    h4 {
      padding: 30px 0;
      font-size: 36px;
      font-weight: normal;
      letter-spacing: 3px;
    }
    p {
      font-size: 20px;
    }
  }
}
.business-step-list {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  margin-bottom: 12px;
  li {
    flex: 1;
    &:nth-of-type(1) {
      border-right: 1px solid #eee;
    }
    h4 {
      font-size: 20px;
      color: #333;
      line-height: 40px;
    }
    p {
      margin: 5px 0 25px;
      line-height: 24px;
      font-size: 16px;
      color: #999;
    }
  }
  :deep(.el-button) {
    width: 160px;
    height: 46px;
    background: #022a75;
    border-radius: 2px;
    text-align: center;
    font-size: 16px;
    line-height: 46px;
    color: #fff;
  }
}
.business-settled-row {
  margin: 0 auto 15px;
}
.settled-content {
  display: flex;
  text-align: left;
  .height260 {
    height: 260px;
  }
  .settled-symbol {
    width: 40px;
    height: 40px;
  }
  .settled-icon {
    position: relative;
    padding: 20px;
    :deep(.el-icon) {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: 56px;
      color: #999;
    }
  }
}
.settled-dlist {
  font-size: 12px;
  .settled-title {
    margin: 20px auto 10px;
    line-height: 24px;
    font-size: 18px;
    color: #333;
  }
  .settled-period {
    margin: 12px 0;
    :deep(.el-button) {
      padding: 0 10px;
      color: #022a75;
      border: 1px solid #022a75;
      border-radius: 2px;
    }
  }
  dd {
    padding-left: 10px;
    line-height: 30px;

    color: #666;
  }
}
</style>
