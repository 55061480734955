<template>
  <div class="">
    <div class="main-carousel-content">
      <!-- 顶部轮播图 -->
      <el-carousel :interval="5000" arrow="always" height="500px">
        <el-carousel-item v-for="(item, i) in carouselOpts" :key="i">
          <img :src="item.imgSrc" class="item-img" :alt="item.alt" />
        </el-carousel-item>
      </el-carousel>
      <div class="person-card-width">
        <div class="person-card-box">
          <person-card></person-card>
        </div>
      </div>
    </div>
    <!-- 商品列表 -->
    <recommend-list></recommend-list>
    <section class="page-section">
      <h3 class="page-title"><span>精选商业</span></h3>
      <ul>
        <li v-for="(item, i) in goodsBusiness" :key="i" class="floor-goods-item">
          <floor-goods :goods="item"></floor-goods>
        </li>
      </ul>
    </section>
    <section class="page-section page-floor-store">
      <floor-store></floor-store>
    </section>
  </div>
</template>

<script>
import RecommendList from "../../components/RecommendList.vue";
import FloorGoods from "./modules/FloorGoods.vue";
import FloorStore from "./modules/FloorStore.vue";
import PersonCard from "./modules/PersonCard.vue";
/****************
 *@author: niubj
 *@date: 2022-01-12 08:54:23
 *@version: V1.0.0
 *@description: 主页面
 *************************************************************************/
export default {
  name: "",
  components: { PersonCard, RecommendList, FloorGoods, FloorStore },
  props: {},
  data() {
    return {
      carouselOpts: [
        {
          imgSrc: "http://www.ofmom.cn/uploads/image/20220209/1644392527.png",
          alt: "妈咪爱",
        },
      ],
      goodsBusiness: [
        {
          id: 21,
          business: require("../../assets/d2aw8d3k1d5cl7ceiiiw.jpg"),
          bName: "",
          goodsOpt: [
            {
              id: 84,
              img: require("../../assets/images/EHA004025C.png"),
              name: "Sanita U-ZA 婴幼儿洗衣液",
              cDrugNm: "Sanita U-ZA 婴幼儿洗衣液",
              businessId: 21,
              factPrice: 103,
              retailPrice: 103,
              specification: "1.5L",
              packageUnit: "瓶",
              manufacture: "北京韩美药品有限公司",
              branchName: "恩济日用旗舰店",
              detailSet: [
                "https://baohuo-open-oss-rmk.oss-cn-beijing.aliyuncs.com/rich/15967655083831.jpg",
                "https://baohuo-open-oss-rmk.oss-cn-beijing.aliyuncs.com/rich/15967655216032.jpg",
                "https://baohuo-open-oss-rmk.oss-cn-beijing.aliyuncs.com/rich/15967655335243.jpg",
              ],
            },
            {
              id: 85,
              img: require("../../assets/images/EOG001009C.png"),
              name: "Sanita U-ZA 婴幼儿洗衣液补充装",
              cDrugNm: "Sanita U-ZA 婴幼儿洗衣液补充装",
              businessId: 21,
              factPrice: 61,
              retailPrice: 61,
              specification: "1L",
              packageUnit: "袋",
              manufacture: "北京韩美药品有限公司",
              branchName: "恩济日用旗舰店",
              detailSet: [
                "http://www.ofmom.cn/uploads/image/20211112/1636702100.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636697255.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636701646.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636698226.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636699105.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636703884.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636705229.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636703113.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636701539.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636695968.jpg",
                "http://www.ofmom.cn/uploads/image/20211112/1636704239.jpg",
              ],
            },
            {
              id: 86,
              name: "Ofmom",
              img: require("../../assets/images/GWSSDKP.png"),
              cDrugNm: "Sanita U-ZA 婴幼儿柔顺剂补充装",
              businessId: 21,
              factPrice: 51,
              retailPrice: 51,
              specification: "1L",
              packageUnit: "袋",
              manufacture: "北京韩美药品有限公司",
              branchName: "恩济日用旗舰店",
              detailSet: [],
            },
            {
              id: 150,
              img: require("../../assets/images/EOG001007C.png"),
              name: "Sanita U-ZA 婴幼儿洗衣液补充装",
              cDrugNm: "Sanita U-ZA 婴幼儿洗衣液补充装",
              businessId: 21,
              factPrice: 75,
              retailPrice: 75,
              specification: "500ml",
              packageUnit: "瓶",
              manufacture: "北京韩美药品有限公司",
              branchName: "恩济日用旗舰店",
              detailSet: [
                "https://baohuo-open-oss-rmk.oss-cn-beijing.aliyuncs.com/rich/15967658853041.jpg",
                "https://baohuo-open-oss-rmk.oss-cn-beijing.aliyuncs.com/rich/15967658959492.jpg",
                "https://baohuo-open-oss-rmk.oss-cn-beijing.aliyuncs.com/rich/15967659089943.jpg",
              ],
            },
            {
              id: 160,
              img: require("../../assets/images/EFB002030C.png"),
              name: "Sanita U-ZA 婴幼儿玩具餐具清洗剂",
              cDrugNm: "Sanita U-ZA 婴幼儿玩具餐具清洗剂",
              businessId: 21,
              factPrice: 78,
              retailPrice: 78,
              specification: "500ml",
              packageUnit: "瓶",
              manufacture: "北京韩美药品有限公司",
              branchName: "恩济日用旗舰店",
              detailSet: [],
            },
            {
              id: 151,
              img: require("../../assets/images/ENC002003C.png"),
              name: "Sanita U-ZA 紫外线消毒柜",
              cDrugNm: "Sanita U-ZA 紫外线消毒柜",
              businessId: 21,
              factPrice: 2680,
              retailPrice: 2680,
              specification: "23.5L",
              packageUnit: "瓶",
              manufacture: "北京韩美药品有限公司",
              branchName: "恩济日用旗舰店",
              detailSet: [
                "https://baohuo-open-oss-rmk.oss-cn-beijing.aliyuncs.com/rich/15967771800314.jpg",
              ],
            },
          ],
        },
        {
          id: 12,
          business: require("../../assets/0zlsw9io1pvopx1gonyf.jpg"),
          bName: "",
          goodsOpt: [
            {
              id: 78,
              img: require("../../assets/images/DUC001007F.png"),
              name: "婴幼儿牙膏 0-2岁",
              cDrugNm: "婴幼儿牙膏 0-2岁",
              businessId: 12,
              factPrice: 42,
              retailPrice: 42,
              specification: "75g",
              packageUnit: "盒",
              manufacture: "北京韩美药品有限公司",
              branchName: "九州通生活旗舰店",
              detailSet: [],
            },
            {
              id: 79,
              img: require("../../assets/images/ENC002002C.png"),
              name: "Sanita-Denti莎卡 婴幼儿牙刷 1阶段",
              cDrugNm: "Sanita-Denti莎卡 婴幼儿牙刷 1阶段",
              businessId: 12,
              factPrice: 28,
              retailPrice: 28,
              specification: "",
              packageUnit: "刷头1.7cm/刷毛高1.0cm/柄长10.5cm",
              manufacture: "北京韩美药品有限公司",
              branchName: "九州通生活旗舰店",
              detailSet: [],
            },
            {
              id: 80,
              img: require("../../assets/images/kcgjmca.jpg"),
              name: "婴幼儿牙刷 2-5岁",
              cDrugNm: "婴幼儿牙刷 2-5岁",
              businessId: 12,
              factPrice: 28,
              retailPrice: 28,
              specification: "",
              packageUnit: "刷头长1.8cm/刷毛高1.1cm /刷柄长13.9cm",
              manufacture: "北京韩美药品有限公司",
              branchName: "九州通生活旗舰店",
              detailSet: [],
            },
            {
              id: 81,
              img: require("../../assets/images/DAB013068D.png"),
              name: "婴幼儿牙膏 2-5岁（三种口味）",
              cDrugNm: "婴幼儿牙膏 2-5岁",
              businessId: 12,
              factPrice: 42,
              retailPrice: 42,
              specification: "75g",
              packageUnit: "盒",
              manufacture: "北京韩美药品有限公司",
              branchName: "九州通生活旗舰店",
              detailSet: [
                "https://baohuo-open-oss-rmk.oss-cn-beijing.aliyuncs.com/rich/15967773088211.jpg",
                "https://baohuo-open-oss-rmk.oss-cn-beijing.aliyuncs.com/rich/15967773201442.jpg",
              ],
            },
            {
              id: 82,
              img: require("../../assets/images/HQS016025G.png"),
              name: "儿童牙刷 5岁+",
              cDrugNm: "儿童牙刷 5岁+",
              businessId: 12,
              factPrice: 28,
              retailPrice: 28,
              specification: "1支",
              packageUnit: "套",
              manufacture: "北京韩美药品有限公司",
              branchName: "九州通生活旗舰店",
              detailSet: [
                "https://baohuo-open-oss-rmk.oss-cn-beijing.aliyuncs.com/rich/16086281673591.jpg",
                "https://baohuo-open-oss-rmk.oss-cn-beijing.aliyuncs.com/rich/16086281490732.jpg",
              ],
            },
            {
              id: 83,
              img: require("../../assets/images/EHA002006C.png"),
              name: "婴幼儿牙膏 5岁+",
              cDrugNm: "婴幼儿牙膏 5岁+",
              businessId: 12,
              factPrice: 42,
              retailPrice: 42,
              specification: "75g",
              packageUnit: "盒",
              manufacture: "北京韩美药品有限公司",
              branchName: "九州通生活旗舰店",
              detailSet: [],
            },
          ],
        },
        {
          id: 33,
          business: require("../../assets/7d1wwd6xmfz7s5n6hj2v.jpg"),
          bName: "",
          goodsOpt: [
            {
              id: 161,
              img: require("../../assets/images/EFA008020C.png"),
              name: "HARMAS PERFECT FIT",
              cDrugNm: "记忆贴合婴儿背带",
              businessId: 33,
              factPrice: 1680,
              retailPrice: 1680,
              specification: "3-36个月",
              packageUnit: "6-22KG",
              manufacture: "北京韩美药品有限公司",
              branchName: "佳能达母婴旗舰店",
              detailSet: [],
            },
            {
              id: 163,
              img: require("../../assets/images/EWN027001C.png"),
              name: "仿生舒睡婴儿床",
              cDrugNm: "仿生舒睡婴儿床",
              businessId: 33,
              factPrice: 768,
              retailPrice: 768,
              specification: "78cm*50cm*13cm",
              packageUnit: "0-24个月",
              manufacture: "北京韩美药品有限公司",
              branchName: "佳能达母婴旗舰店",
              detailSet: [
                "https://baohuo-open-oss-rmk.oss-cn-beijing.aliyuncs.com/rich/16086137463041_02.png",
                "https://baohuo-open-oss-rmk.oss-cn-beijing.aliyuncs.com/rich/16086137793352_02.png",
              ],
            },
            {
              id: 164,
              img: require("../../assets/images/BUC007007F.png"),
              name: "多功能便携婴儿床",
              cDrugNm: "多功能便携婴儿床",
              businessId: 33,
              factPrice: 688,
              retailPrice: 688,
              specification: "78cm*50cm*13cm",
              packageUnit: "0-24个月",
              manufacture: "北京韩美药品有限公司",
              branchName: "佳能达母婴旗舰店",
              detailSet: [],
            },
            {
              id: 149,
              img: require("../../assets/images/kfysby.png"),
              name: "HARMAS LIGHT FIT",
              cDrugNm: "纤巧轻舒婴儿背带",
              businessId: 33,
              factPrice: 1680,
              retailPrice: 1680,
              specification: "3-36个月",
              packageUnit: "6-22KG",
              manufacture: "北京韩美药品有限公司",
              branchName: "佳能达母婴旗舰店",
              detailSet: [],
            },
            {
              id: 154,
              img: require("../../assets/images/ENA005155C.png"),
              name: "哄睡婴儿摇椅",
              cDrugNm: "哄睡婴儿摇椅",
              businessId: 33,
              factPrice: 868,
              retailPrice: 868,
              specification: "78cm*50cm*13cm",
              packageUnit: "0-24个月",
              manufacture: "北京韩美药品有限公司",
              branchName: "佳能达母婴旗舰店",
              detailSet: [],
            },
            {
              id: 200,
              img: require("../../assets/images/tdx123.jpg"),
              name: "HARMAS",
              cDrugNm: "多功能妈咪包",
              businessId: 33,
              factPrice: 1299,
              retailPrice: 1299,
              specification: "出行",
              packageUnit: "",
              manufacture: "北京韩美药品有限公司",
              branchName: "佳能达母婴旗舰店",
              detailSet: [],
            },
          ],
        },
        {
          id: 52,
          business: require("../../assets/bqqgyc6n7y06gmuzis0c.jpg"),
          bName: "",
          goodsOpt: [
            {
              id: 159,
              img: require("../../assets/images/DOE001070C.png"),
              name: "Ofmom Yoom Signature",
              cDrugNm: "婴儿手推车玫瑰金车架闪金色",
              businessId: 52,
              factPrice: 10999,
              retailPrice: 10999,
              specification: "0-22KG",
              packageUnit: "0-36个月",
              manufacture: "北京韩美药品有限公司",
              branchName: "明朗生活旗舰店",
              detailSet: [],
            },
            {
              id: 153,
              img: require("../../assets/images/HQS016011G.png"),
              name: "Ofmom Yoom Signature",
              cDrugNm: "婴儿手推车黑色车架珍珠白蜂巢色",
              businessId: 52,
              factPrice: 8999,
              retailPrice: 8999,
              specification: "0-22KG",
              packageUnit: "0-36个月",
              manufacture: "北京韩美药品有限公司",
              branchName: "明朗生活旗舰店",
              detailSet: [],
            },
            {
              id: 155,
              img: require("../../assets/images/HQB002010C.png"),
              name: "Ofmom Yoom Signature",
              cDrugNm: "婴儿手推车黑色车架碳黑蜂巢色",
              businessId: 52,
              factPrice: 8999,
              retailPrice: 8999,
              specification: "0-22KG",
              packageUnit: "0-36个月",
              manufacture: "北京韩美药品有限公司",
              branchName: "明朗生活旗舰店",
              detailSet: [],
            },
            {
              id: 156,
              img: require("../../assets/images/HGF005001C.png"),
              name: "Ofmom Yoom Signature",
              cDrugNm: "婴儿手推车黑色车架巴黎薄荷绿色",
              businessId: 52,
              factPrice: 9399,
              retailPrice: 9399,
              specification: "0-22KG",
              packageUnit: "0-36个月",
              manufacture: "北京韩美药品有限公司",
              branchName: "明朗生活旗舰店",
              detailSet: [],
            },
            {
              
              id: 157,
              img: require("../../assets/images/ILELEYSJ20P.png"),
              name: "Ofmom Yoom Easy Folding",
              cDrugNm: "婴儿手推车黑色车架巴黎粉色",
              businessId: 52,
              factPrice: 2999,
              retailPrice: 2999,
              specification: "0-22KG",
              packageUnit: "0-36个月",
              manufacture: "北京韩美药品有限公司",
              branchName: "明朗生活旗舰店",
              detailSet: [],
            },
            {
              id: 158,
              img: require("../../assets/images/DAB007021D.png"),
              name: "Ofmom Yoom Easy Folding",
              cDrugNm: "婴儿手推车黑色车架巴黎白色",
              businessId: 52,
              factPrice: 2999,
              retailPrice: 2999,
              specification: "0-22KG",
              packageUnit: "0-36个月",
              manufacture: "北京韩美药品有限公司",
              branchName: "明朗生活旗舰店",
              detailSet: [],
            },
          ],
        },
      ],
    };
  },

  computed: {},
  watch: {},
  methods: {},
};
</script>
<style lang="less" scoped>
.main-carousel-content {
  position: relative;
  .item-img {
    width: 100%;
    height: auto;
  }
}
.person-card-width {
  position: absolute;
  right: 0;
  top: 245px;
  width: calc(100% - 1200px);
  .person-card-box {
    position: absolute;
    height: 230px;
    width: 245px;
    top: 0;
    bottom: 0;
    right: 245px;
    margin: auto;
  }
}
.page-section {
  &.page-floor-store {
    padding-top: 1px;
    padding-bottom: 2rem;
  }
}
.floor-goods-item {
  margin-bottom: 15px;
}
</style>
